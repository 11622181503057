import { TabContext, TabPanel } from "@mui/lab";
import { Pagination, Tab, Tabs } from "@mui/material";

import {
  ReservationsListComponent,
  ReservationsListComponentCard,
} from "app/reservation/components/ReservationsListComponent";
import { PageBody, PageContainer } from "layout/components";
import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "shared/components";
import { Reservation } from "../main/models/Reservation";
import {
  ReservationStatus,
  useReservationsByStatusQuery,
} from "../main/services/reservationRepository";
import { getRequerimientos } from "../main/utils/getRequerimientos";

function ReservasPendientes(props: {
  reservasPendientes: Reservation[];
  setPage: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  total: number;
  loading: boolean;
  copy_link: boolean;
}) {
  return (
    <>
      <ReservationsListComponent
        isEmpty={(props.reservasPendientes ?? []).length === 0}
        loading={props.loading}
        isEmptyMessage="No hay reservas pendientes"
      >
        {(props.reservasPendientes ?? []).map((reservation) => (
          <ReservationsListComponentCard
            to={`pending/${reservation.id}`}
            itinerario={reservation.locations}
            key={reservation.id}
            copy_link={props.copy_link}
            id={reservation.id}
            fecha={moment(reservation.reservationDatetime).format(
              "DD/MM/YYYY HH:mm [hs.]"
            )}
            pasajeros={reservation.locations.flatMap((loc) =>
              loc.passengers.map((x) => x.name)
            )}
            phones={reservation.locations.flatMap((loc) =>
              loc.passengers.map((x) => x.phone)
            )}
            precio={`${
              reservation.estimated_prices?.transfer_price +
                reservation.estimated_prices?.expenses_price || 0
            } (estimado)`}
            requerimientos={getRequerimientos(reservation.requirements)}
          />
        ))}
      </ReservationsListComponent>
      {props.reservasPendientes?.length > 0 ? (
        <Pagination
          className="flex justify-center p-5"
          count={Math.ceil(props.total / 10)}
          page={props.page}
          onChange={props.setPage}
        />
      ) : null}
    </>
  );
}

function ReservasEnViaje(props: {
  reservasEnViaje: Reservation[];
  setPage: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  total: number;
  loading: boolean;
  copy_link: boolean;
}) {
  return (
    <>
      <ReservationsListComponent
        loading={props.loading}
        isEmpty={(props.reservasEnViaje ?? []).length === 0}
        isEmptyMessage="No hay reservas en viaje"
      >
        {(props.reservasEnViaje ?? []).map((reservation: Reservation) => (
          <ReservationsListComponentCard
            to={`on-course/${reservation.id}`}
            key={reservation.id}
            id={reservation.id}
            copy_link={props.copy_link}
            itinerario={reservation.locations}
            fecha={moment(reservation.reservationDatetime).format(
              "DD/MM/YYYY HH:mm [hs.]"
            )}
            precio={`${
              reservation.estimated_prices?.transfer_price +
                reservation.estimated_prices?.expenses_price || 0
            } (estimado)`}
            pasajeros={reservation.locations.flatMap((loc) =>
              loc.passengers.map((x) => x.name)
            )}
            requerimientos={getRequerimientos(reservation.requirements)}
            codigoChofer={reservation.assigned_driver?.code}
          />
        ))}
      </ReservationsListComponent>
      {props.reservasEnViaje?.length > 0 ? (
        <Pagination
          className="flex justify-center p-5"
          count={Math.ceil(props.total / 10)}
          page={props.page}
          onChange={props.setPage}
        />
      ) : null}
    </>
  );
}

function ReservasFinalizadas(props: {
  reservasFinalizadas: Reservation[];
  setPage: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  total: number;
  loading: boolean;
}) {
  return (
    <>
      <ReservationsListComponent
        isEmpty={(props.reservasFinalizadas ?? []).length === 0}
        isEmptyMessage="No hay reservas finalizadas"
        loading={props.loading}
      >
        {(props.reservasFinalizadas ?? []).map((reservation: Reservation) => (
          <ReservationsListComponentCard
            to={`finished/${reservation.id}`}
            key={reservation.id}
            id={reservation.id}
            itinerario={reservation.locations}
            fecha={moment(reservation.reservationDatetime).format(
              "DD/MM/YYYY HH:mm [hs.]"
            )}
            pasajeros={reservation.locations.flatMap((loc) =>
              loc.passengers.map((x) => x.name)
            )}
            precio={`${
              reservation.final_prices?.transfer_price +
                reservation.final_prices?.expenses_price ||
              reservation.calculated_prices?.transfer_price +
                reservation.calculated_prices?.expenses_price ||
              0
            }`}
            requerimientos={getRequerimientos(reservation.requirements)}
          />
        ))}
      </ReservationsListComponent>
      {props.reservasFinalizadas?.length > 0 ? (
        <Pagination
          className="flex justify-center p-5"
          count={Math.ceil(props.total / 10)}
          page={props.page}
          onChange={props.setPage}
        />
      ) : null}
    </>
  );
}

export const ReservationListPage = () => {
  const [selectedTab, setSelectedTab] = useSearchParams({ tab: "0" });

  const [pendientesPage, setPendientesPage] = useState(1);
  const [enViajePage, setEnViajePage] = useState(1);
  const [finalizadasPage, setFinalizadasPage] = useState(1);

  const reservasPendientesQuery = useReservationsByStatusQuery(
    ReservationStatus.PENDIENTE,
    pendientesPage
  );
  const reservasEnViajeQuery = useReservationsByStatusQuery(
    ReservationStatus.EN_VIAJE,
    enViajePage
  );
  const reservasFinalizadasQuery = useReservationsByStatusQuery(
    ReservationStatus.FINALIZADA,
    finalizadasPage
  );

  const handleTabChange = (tab: string) => {
    if (tab === "0") {
      reservasPendientesQuery.refetch();
    } else if (tab === "1") {
      reservasEnViajeQuery.refetch();
    } else {
      reservasFinalizadasQuery.refetch();
    }
    setSelectedTab({ tab }, { replace: true });
  };

  return (
    <PageContainer>
      <PageBody>
        <Card>
          <TabContext value={selectedTab.get("tab") ?? "0"}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: 5,
                  borderRadius: 90,
                  maxWidth: "",
                },
              }}
              style={{ marginBottom: 20 }}
              centered
              value={selectedTab.get("tab") ?? "0"}
              onChange={(_, value) => handleTabChange(value)}
            >
              <Tab
                disabled={reservasPendientesQuery.isFetching}
                className="text-xs font-semibold"
                label="PENDIENTES"
                value={"0"}
              />
              <Tab
                disabled={reservasEnViajeQuery.isFetching}
                className="text-xs font-semibold"
                label="EN VIAJE"
                value={"1"}
              />
              <Tab
                disabled={reservasFinalizadasQuery.isFetching}
                className="text-xs font-semibold"
                label="FINALIZADAS"
                value={"2"}
              />
            </Tabs>
            <TabPanel value={"0"} style={{ padding: 0 }}>
              <ReservasPendientes
                reservasPendientes={reservasPendientesQuery.data?.data ?? []}
                setPage={(e: React.ChangeEvent<unknown>, value: number) => {
                  setPendientesPage(value);
                }}
                page={pendientesPage}
                total={reservasPendientesQuery.data?.total ?? 0}
                loading={reservasPendientesQuery.isFetching}
                copy_link={true}
              ></ReservasPendientes>
            </TabPanel>
            <TabPanel value={"1"} style={{ padding: 0 }}>
              <ReservasEnViaje
                reservasEnViaje={reservasEnViajeQuery.data?.data ?? []}
                setPage={(e: React.ChangeEvent<unknown>, value: number) => {
                  setEnViajePage(value);
                }}
                page={enViajePage}
                total={reservasEnViajeQuery.data?.total ?? 0}
                loading={reservasEnViajeQuery.isFetching}
                copy_link={true}
              ></ReservasEnViaje>
            </TabPanel>
            <TabPanel value={"2"} style={{ padding: 0 }}>
              <ReservasFinalizadas
                reservasFinalizadas={reservasFinalizadasQuery.data?.data ?? []}
                setPage={(e: React.ChangeEvent<unknown>, value: number) => {
                  setFinalizadasPage(value);
                }}
                page={finalizadasPage}
                total={reservasFinalizadasQuery.data?.total ?? 0}
                loading={reservasFinalizadasQuery.isFetching}
              ></ReservasFinalizadas>
            </TabPanel>
          </TabContext>
        </Card>
      </PageBody>
    </PageContainer>
  );
};
