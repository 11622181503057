import { useSuperQuery } from "@vadiun/react-hooks";
import { httpClient } from "shared/services";
import { authorizedBuilder, AuthorizedModel } from "../models/Authorized";

export class AuthorizedService {
  keys = {
    byClientBusinessId: (idClientBusiness: number) => [
      "authorized",
      { idClientBusiness },
    ],
  };

  async getAuthorizeds(idClientBusiness: number): Promise<AuthorizedModel[]> {
    const response = await httpClient.get(
      `business-clients/${idClientBusiness}/authorized`
    );
    return response.map(authorizedBuilder.fromBackEnd);
  }
}

const repo = new AuthorizedService();

export const useAuthorizedsByClientBusinessIdQuery = (
  idClientBusiness: number,
  options: { enabled: boolean }
) =>
  useSuperQuery(
    repo.keys.byClientBusinessId(idClientBusiness),
    () => repo.getAuthorizeds(idClientBusiness),
    options
  );
