import { ReservationServiceTypeEnum } from "app/reservation/main/models/ReservationServiceType";
import { ServiceTypeFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { useState } from "react";
import { RadioButton, RadioButtonItem } from "shared/components";

export const ReservationServiceTypeFrom: ServiceTypeFormComponent = ({
  onSubmit,
  actions,
  serviceTypes,
  initialValues,
}) => {
  const [serviceType, setServiceType] =
    useState<ReservationServiceTypeEnum>(initialValues);
  return (
    <div>
      <RadioButton value={serviceType} onChange={setServiceType}>
        <div className="flex flex-col gap-4">
          {serviceTypes.map((type) => (
            <RadioButtonItem key={type} label={type} value={type} />
          ))}
        </div>
      </RadioButton>
      {actions(() => onSubmit(serviceType))}
    </div>
  );
};
