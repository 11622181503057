import { faCar, faEllipsisH, faList, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "assets/logo.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SidebarHeader, SidebarItem } from ".";
import { useAuthService } from '../../../app/auth/services/AuthService';

export type SideBarStatus =
  | {
      platform: "mobile";
      status: "open" | "closed";
    }
  | {
      platform: "desktop";
      status: "collapsed" | "expanded";
    };
interface Props {
  status: SideBarStatus;
  toggleCollapse: () => void;
}
interface PropsSidebarSection {
  titulo: string;
  isCollapsed: boolean;
}

const SidebarSection = ({ titulo, isCollapsed }: PropsSidebarSection) => {
  return (
    <h1 className="text-blueGray-700 mt-7 mb-4 px-8 text-xs font-semibold">
      {!isCollapsed ? titulo : <FontAwesomeIcon icon={faEllipsisH} size="1x" />}
    </h1>
  );
};

export const Sidebar = ({ status, toggleCollapse }: Props) => {
  const authService = useAuthService()
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isTemporarilyExpanded, setIsTemporarilyExpanded] = useState(false);
  const location = useLocation();
  const isCollapsed =
    status.platform === "desktop" &&
    status.status === "collapsed" &&
    !isTemporarilyExpanded;

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  const colapsedClass = () => {
    const baseClass =
      "bg-blueGray-800 min-h-full shadow-md flex-shrink-0 transition-all duration-300 ease-in-out";
    if (status.platform === "mobile") {
      const mobileBaseClass = `${baseClass} fixed`;
      const mobileClosedClass = `${mobileBaseClass} hidden hide-sidebar`;
      return status.status === "closed" ? mobileClosedClass : mobileBaseClass;
    }
    if (status.platform === "desktop") {
      const desktopBaseClass = `${baseClass} block show-sidebar relative`;
      const desktopCollapsedClass = `${desktopBaseClass} w-20`;
      const desktopExpandedClass = `${desktopBaseClass} w-60`;
      return !isCollapsed ? desktopExpandedClass : desktopCollapsedClass;
    }
  };

  const onMouseOver = () => {
    if (status.platform === "desktop" && status.status === "collapsed") {
      setIsTemporarilyExpanded(true);
    }
  };

  const onMouseOut = () => {
    if (status.platform === "desktop" && status.status === "collapsed") {
      setIsTemporarilyExpanded(false);
    }
  };

  const isAdmin = authService.loggedUser?.roles.includes('Administrador portal')

  return (
    <>
      <div
        className={colapsedClass()}
        style={{ zIndex: 500, backgroundColor: "#1e1e2d" }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}
      >
        <SidebarHeader
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          isMobile={status.platform === "mobile"}
          logo={Logo}
        />
        <SidebarItem
          icon={<FontAwesomeIcon icon={faCar} size="1x" />}
          label="Reservar"
          isSelected={selectedItem === "/main/reservations/create"}
          redirectTo="/main/reservations/create"
          isCollapsed={isCollapsed}
        />
        <SidebarItem
          icon={<FontAwesomeIcon icon={faList} size="1x" />}
          label="Mis reservas"
          isSelected={selectedItem === "/main/reservations"}
          redirectTo="/main/reservations"
          isCollapsed={isCollapsed}
        />
        {isAdmin ?
        <SidebarItem
          icon={<FontAwesomeIcon icon={faUserCircle} size="1x" />}
          label="Solicitantes"
          isSelected={selectedItem === "/main/users/list"}
          redirectTo="/main/users/list"
          isCollapsed={isCollapsed}
        /> : null}
      </div>
    </>
  );
};

