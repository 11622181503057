import React, { createContext, useContext, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { Card } from "../Card";
interface Props {
  children: React.ReactNode;
  step: string | number;
}

interface StepperContextType {
  currentStep: string | number;
}

const StepperContext = createContext({} as StepperContextType);

const MobileStepper = ({ children, step }: Props) => {
  useEffect(() => {
    document.getElementById("stepper-item-" + step)?.scrollIntoView();
  }, [step]);
  return (
    <div className="hide-scroll flex snap-x snap-mandatory gap-8 overflow-x-scroll scroll-smooth">
      <StepperContext.Provider value={{ currentStep: step }}>
        {children}
      </StepperContext.Provider>
    </div>
  );
};

const DesktopStepper = ({ children, step }: Props) => {
  return (
    <div className="flex flex-col gap-8">
      <StepperContext.Provider value={{ currentStep: step }}>
        {children}
      </StepperContext.Provider>
    </div>
  );
};

export const Stepper = ({
  children,
  step,
  steps,
}: Props & { steps: React.ReactNode }) => {
  return (
    <Card>
      <div className="md:hidden">
        <MobileStepper step={step}>{steps}</MobileStepper>
        {children}
      </div>
      <div className="hidden gap-16 md:flex">
        <DesktopStepper step={step}>{steps}</DesktopStepper>

        {children}
      </div>
    </Card>
  );
};

export interface StepperItemProps {
  title: string;
  subtitle?: string;
  step: string | number;
  completed?: boolean;
  stepNumber: number;
}

Stepper.Item = ({
  step,
  title,
  subtitle,
  completed,
  stepNumber,
}: StepperItemProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentStep } = useContext(StepperContext);

  const badgeColor = currentStep === step ? "bg-primary-500" : "bg-primary-100";
  const textColor =
    currentStep === step ? "text-primary-100" : "text-primary-500";

  const badgeContent = completed ? (
    <FaCheck className={`${textColor}`} />
  ) : (
    <span className={`${textColor}`}>{stepNumber}</span>
  );
  return (
    <div
      className="flex min-w-[calc(100vw-64px)] snap-center md:min-w-fit"
      id={"stepper-item-" + step}
    >
      <div
        className={`mr-8 flex h-10 w-10 items-center justify-center rounded-md  ${badgeColor}`}
      >
        {badgeContent}
      </div>
      <div>
        <h1 className="text-typo-800 font-bold">{title}</h1>
        <p className="text-typo-500 text-xs">{subtitle}</p>
      </div>
    </div>
  );
};
