import { useSuperMutation } from "@vadiun/react-hooks";
import { domicilioBuilder } from "app/main/address/models/Domicilio";
import { httpClient } from "shared/services";
import {
  ReservationClientBusinessCreateType,
  ReservationClientPrivateCreateType,
} from "./reservationCreateType";

export class ReservationService {
  /**
   * Crea una reserva para un cliente particular
   */
  createClientPrivateReservation(
    reservation: ReservationClientPrivateCreateType
  ) {
    return httpClient.post(`users/me/transfer-reservations`, {
      reservation_datetime:
        reservation.reservationDateTime.format("YYYY-MM-DD HH:mm") + ":00",
      locations: reservation.itinerary.map((location, index) => ({
        address: domicilioBuilder.toBackEnd(location.address),
        passengers: index === 0 ? [reservation.passanger] : [],
      })),
      coordination_observation: reservation.observation,
      pay_with_cash: true,
      service_type: reservation.serviceType,
    });
  }

  /**
   * Crea una reserva para un cliente de empresa
   */
  createClientBusinessReservation(
    reservation: ReservationClientBusinessCreateType
  ) {
    let requirementsBack: any = {};
    const { requirements } = reservation;
    if (requirements.costCenter) {
      requirementsBack.cost_center = requirements.costCenter;
    }
    if (requirements.sector) {
      requirementsBack.sector = requirements.sector;
    }
    if (requirements.authorizedId) {
      requirementsBack.authorized_id = requirements.authorizedId;
    }
    if (requirements.buyOrder) {
      requirementsBack.buy_order = requirements.buyOrder;
    }
    if (requirements.employeeNumber) {
      requirementsBack.employee_number = requirements.employeeNumber;
    }
    if (requirements.platformApplication) {
      requirementsBack.platform_application = requirements.platformApplication;
    }
    if (requirements.tripNumber) {
      requirementsBack.trip_number = requirements.tripNumber;
    }
    if (requirements.data1) {
      requirementsBack.data_1 = requirements.data1;
    }
    if (requirements.data2) {
      requirementsBack.data_2 = requirements.data2;
    }
    if (requirements.data3) {
      requirementsBack.data_3 = requirements.data3;
    }
    return httpClient.post(`users/me/transfer-reservations`, {
      reservation_datetime:
        reservation.reservationDateTime.format("YYYY-MM-DD HH:mm") + ":00",
      locations: reservation.itinerary.map((location, index) => ({
        address: domicilioBuilder.toBackEnd(location.address),
        passengers: index === 0 ? [reservation.passanger] : [],
      })),
      coordination_observation: reservation.observation,
      pay_with_cash: reservation.payWithCash,
      service_type: reservation.serviceType,
      requirement: reservation.payWithCash
        ? undefined
        : {
            applicant_name: reservation.requirements.applicant_name,
            applicant_email: reservation.requirements.applicant_email,
            ...requirementsBack,
          },
    });
  }
}

const repo = new ReservationService();
export const useCreateBusinessClientReservationMutation = () =>
  useSuperMutation(repo.createClientBusinessReservation, {
    successMessageType: "message",
    messages: {
      success: {
        title: "Ya registramos tu reserva 😃",
        message: "Vamos a mandarte un email cuando podamos confirmarla.",
      },
    },
  });

export const useCreateReservationMutation = () =>
  useSuperMutation(repo.createClientPrivateReservation, {
    successMessageType: "message",
    messages: {
      success: {
        title: "Ya registramos tu reserva 😃",
        message: "Vamos a mandarte un email cuando podamos confirmarla.",
      },
    },
  });
