import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { Message, Snackbar, Spinner } from "@vadiun/react-components";
import {
  MessageProvider,
  SnackbarProvider,
  SpinnerProvider,
} from "@vadiun/react-hooks";
import moment from "moment";
import "moment/locale/es";
import { SnackbarProvider as NotiStackProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";
import { router } from "routes";
import "./App.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

moment.locale("es");

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },

  palette: {
    primary: {
      main: "#37bdf8",
    },
    secondary: {
      main: "#37bdf8",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <NotiStackProvider>
            <SpinnerProvider component={Spinner}>
              <MessageProvider component={Message}>
                <SnackbarProvider component={Snackbar}>
                  <RouterProvider router={router} />
                </SnackbarProvider>
              </MessageProvider>
            </SpinnerProvider>
          </NotiStackProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
