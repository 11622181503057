import React from "react";

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const PageBody = ({ children, style }: Props) => {
  return (
    <div
      className="flex min-h-[calc(100vh-68px)] w-full justify-center p-4 pt-8"
      style={style}
    >
      <div className="w-full max-w-6xl">{children}</div>
    </div>
  );
};
