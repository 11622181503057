import { FormControl, InputLabel, MenuItem, TextField as TextFieldMaterial } from "@mui/material";
import {
  ClientRequeriments,
  FormRequeriments,
} from "app/reservation/clientBusiness/hooks/useReservationClientBusinessCreate";
import { RequerimentsFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { Field, Form, Formik } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Select,
  TextField,
} from "formik-mui";
import { QueryData } from "shared/models/QueryData";
import * as Yup from "yup";

const validation = (
  requeriments: QueryData<ClientRequeriments>
): Yup.SchemaOf<FormRequeriments> =>
  Yup.object({
    authorizedId: requeriments.needsAuthorized
      ? Yup.string().required("El autorizado es requerido")
      : Yup.string(),
    costCenter: requeriments.needsCostCenter
      ? Yup.string().required("El centro de costos es requerido")
      : Yup.string(),
    sector: requeriments.needsSector ? Yup.string().required() : Yup.string(),
    buyOrder: requeriments.needsBuyOrder
      ? Yup.string().required("La orden de compra es requerida")
      : Yup.string(),
    employeeNumber: requeriments.needsEmployeeNumber
      ? Yup.string().required("El legajoes requerido")
      : Yup.string(),
    platformApplication: requeriments.needsPlatformApplication
      ? Yup.string().required("La solicitud en plataforma es requerida")
      : Yup.string(),
    tripNumber: requeriments.needsTripNumber
      ? Yup.string().required("El numero de viaje es requerido")
      : Yup.string(),
    data1: requeriments.needsData1
      ? Yup.string().required("El dato 1 es requerido")
      : Yup.string(),
    data2: requeriments.needsData2
      ? Yup.string().required("El dato 2 es requerido")
      : Yup.string(),
    data3: requeriments.needsData3
      ? Yup.string().required("El dato 3 es requerido")
      : Yup.string(),
  });

export const ReservationRequerimentsForm: RequerimentsFormComponent = ({
  onSubmit,
  requeriments,
  cost_centers,
  authorizeds,
  actions,
  initialValues,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validation(requeriments)}
    >
      {(formik) => (
        <Form
          onSubmit={formik.handleSubmit}
          className="grid grid-cols-1 gap-6 pb-8 md:grid-cols-2"
        >
          {requeriments.needsAuthorized && (
            <Field
              name="authorizedId"
              component={Autocomplete}
              fullWidth
              getOptionLabel={(id: number) =>
                authorizeds.find((x) => x.id === id)?.name ?? ""
              }
              options={authorizeds.map((x) => x.id)}
              className="md:col-span-2"
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={!!formik.errors.authorizedId}
                  helperText={formik.errors.authorizedId}
                  label="Autorizado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
          {requeriments.needsCostCenter && (
            !cost_centers!.length ? (
              <Field
                name="costCenter"
                label="Centro de costos"
                variant="outlined"
                component={TextField}
                className="col-span-1"
                fullWidth
              /> ) : (
              <FormControl variant="outlined" fullWidth className="col-span-1">
                <Field
                  component={Select}
                  name="costCenter"
                  label="Centro de costos"
                >
                  {cost_centers?.map((center) => (
                    <MenuItem key={center.id} value={center.name}>
                      {center.name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            ) 
          
          )}
          {requeriments.needsSector && (
            <Field
              name="sector"
              label="Sector"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsBuyOrder && (
            <Field
              name="buyOrder"
              label="Orden de compra"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsEmployeeNumber && (
            <Field
              name="employeeNumber"
              label="Legajo"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsPlatformApplication && (
            <Field
              name="platformApplication"
              label="Solicitud en plataforma"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsTripNumber && (
            <Field
              name="tripNumber"
              label="Número de viaje"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsData1 && (
            <Field
              name="data1"
              label="Dato 1"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsData2 && (
            <Field
              name="data2"
              label="Dato 2"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          {requeriments.needsData3 && (
            <Field
              name="data3"
              label="Dato 3"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          )}
          <div className="md:col-span-2">{actions(formik.submitForm)}</div>
        </Form>
      )}
    </Formik>
  );
};
