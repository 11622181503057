export interface ISolicitante {
    id?: number;
    name: string;
    email: string;
    roles?: string[];
}

export class Solicitante {
    id?: number;
    name: string;
    email: string;
    roles?: string[];
    
  constructor(args: ISolicitante) {
    this.id = args.id;
    this.name = args.name;
    this.email = args.email; 
    this.roles = args.roles;          
  }
}