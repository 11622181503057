export interface DomicilioGoogle {
  latitud: number;
  longitud: number;
  calle: string;
  numero: null | number | string;
  partido: string;
  localidad: string;
  provincia: string;
  pais?: string;
  codigoPostal?: string;
  text?: string;
}

export const mockedDomicilioGoogle = (): DomicilioGoogle => {
  return {
    latitud: 0,
    longitud: 0,
    calle: "",
    numero: "",
    partido: "",
    localidad: "",
    provincia: "",
    pais: "",
    codigoPostal: "",
    text: "",
  };
};

export const createDomicilioText = (
  domicilio: Pick<
    DomicilioGoogle,
    "calle" | "numero" | "localidad" | "provincia"
  >
) => {
  let dom = "";
  if (domicilio.calle) {
    dom = domicilio.calle;
  }
  if (domicilio.numero) {
    dom = `${dom} ${domicilio.numero}`;
  }
  if (domicilio.localidad) {
    dom = `${dom}, ${domicilio.localidad}`;
  }
  // if (domicilio.provincia) {
  //   dom = `${dom}, ${domicilio.provincia}`;
  // }
  return dom;
};

export const createDomicilioStreetText = (
  domicilio: Pick<
    DomicilioGoogle,
    "calle" | "numero" | "localidad" | "provincia"
  >
) => {
  let dom = "";
  if (domicilio.calle) {
    dom = domicilio.calle;
  }
  if (domicilio.numero) {
    dom = `${dom} ${domicilio.numero}`;
  }
  return dom;
};
