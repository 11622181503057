import { Spinner } from "@vadiun/react-components";
import CarBlue from "assets/hatchback.png";
import Logo from "assets/logo2.png";
import moment from "moment";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Card, DynamicMap } from "shared/components";
import {
  PublicReservationDriver,
  PublicReservationItinerarioItem,
} from "../components/PublicReservationComponent";
import { usePublicReservationQuery } from "../main/services/reservationRepository";

const Header = () => {
  return (
    <div className="flex items-center gap-8 bg-[#1a69a1] px-8 py-4">
      <img src={Logo} className="h-8" alt="logo" />
      <p className="text-bold text-white">Las Leñas Traslados</p>
    </div>
  );
};

export const PublicReservationPage = () => {
  const { id } = useParams<{ id: string }>();
  const reservationQuery = usePublicReservationQuery(id ?? "");
  const center = useMemo(() => {
    if (reservationQuery.data === undefined) {
      return undefined;
    }
    return {
      lat: reservationQuery.data.chofer.latitude,
      lng: reservationQuery.data.chofer.longitude,
    };
  }, [
    reservationQuery.data?.chofer.latitude,
    reservationQuery.data?.chofer.longitude,
  ]);

  if (reservationQuery.isLoading) {
    return (
      <div>
        <Header />
        <div className="flex min-h-full w-full justify-center p-4 pt-8">
          <Spinner isVisible />
        </div>
      </div>
    );
  }
  if (reservationQuery.isError) {
    return (
      <div>
        <Header />
        <div className="flex min-h-full w-full justify-center p-4 pt-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">Atención</h1>
            <p className="text-gray-500">
              {String((reservationQuery.error as any)?.message)}
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (reservationQuery.data === undefined) {
    return (
      <div>
        <Header />
        <div className="flex min-h-full w-full justify-center p-4 pt-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">Atención</h1>
            <p className="text-gray-500">Reserva no encontrada</p>
          </div>
        </div>
      </div>
    );
  }

  const driverMarker = {
    lat: reservationQuery.data.chofer.latitude,
    lng: reservationQuery.data.chofer.longitude,
    icon: {
      scaledSize: new google.maps.Size(40, 40),
      url: CarBlue,
      anchor: new google.maps.Point(20, 20),
    },
  };
  const itinerarioMarkers = reservationQuery.data.itinerario.map(
    (itinerario, index) => ({
      lat: itinerario.latitude,
      lng: itinerario.longitude,
      label: String(index),
    })
  );

  return (
    <div>
      <Header />

      <div className="flex min-h-full w-full justify-center p-4 pt-8">
        <div className="w-full max-w-6xl">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <DynamicMap
                defaultCenter={center}
                markers={[driverMarker, ...itinerarioMarkers]}
                polyline={{
                  path: reservationQuery.data.recorrido.map((x) => ({
                    lat: x.latitude,
                    lng: x.longitude,
                  })),
                }}
                containerElement={
                  <div
                    className="overflow-hidden rounded-md"
                    style={{ height: `500px`, width: "100%" }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
            <Card
              title="Resumen de la reserva"
              className="col-span-12 md:col-span-6 "
            >
              <div className="mt-2 flex flex-col gap-2">
                <div className="flex gap-1">
                  <p className="text-typo-500 text-sm">Identificación: </p>
                  <p className="text-typo-700 text-sm">
                    {reservationQuery.data.id}
                  </p>
                </div>
                <div className="flex gap-1">
                  <p className="text-typo-500 text-sm">Fecha: </p>
                  <p className="text-typo-700 text-sm">
                    {moment(reservationQuery.data.fecha).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="flex gap-1">
                  <p className="text-typo-500 text-sm">Hora: </p>
                  <p className="text-typo-700 text-sm">
                    {moment(reservationQuery.data.fecha).format("HH:mm")}
                  </p>
                </div>
                <div className="flex gap-1">
                  <p className="text-typo-500 text-sm">KM estimados: </p>
                  <p className="text-typo-700 text-sm">
                    {reservationQuery.data.km_estimados}
                  </p>
                </div>
                <div className="flex gap-1">
                  <p className="text-typo-500 text-sm">
                    Tiempo estimado de viaje:{" "}
                  </p>
                  <p className="text-typo-700 text-sm">
                    {reservationQuery.data.tiempo_estimado_viaje} min
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  {reservationQuery.data.itinerario.map((itinerario, index) => (
                    <PublicReservationItinerarioItem
                      key={index}
                      index={index}
                      lastIndex={
                        index + 1 === reservationQuery.data.itinerario.length
                      }
                      address={itinerario.nombre}
                    />
                  ))}
                </div>
                <PublicReservationDriver
                  chofer_name={reservationQuery.data.chofer.nombre}
                  chofer_photo={reservationQuery.data.chofer.foto}
                  vehicle_photo={reservationQuery.data.vehiculo.foto}
                  vehicle_plate={reservationQuery.data.vehiculo.patente}
                  vehicle_model={`
                  ${reservationQuery.data.vehiculo.marca} ${reservationQuery.data.vehiculo.modelo}
                `}
                />
                <div className="my-5 flex flex-col gap-1 text-sm text-[#e60000]">
                  <span>
                    Puede calificar su viaje ingresando al siguiente link:
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://forms.gle/QXLJuTPAcyx7NpaB8"
                  >
                    https://forms.gle/QXLJuTPAcyx7NpaB8
                  </a>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
