import { useNavigate } from "react-router-dom";
import { LoginCredentials } from "../models/User";
import React, { useContext, useEffect, useState } from "react";
import { useAuthRepository } from "./AuthRepository";
import decode from "jwt-decode";
import { LoggedUser } from "../models/LoggedUser";

export interface AuthContextType {
  isAuthenticated: boolean;
  loggedUser: LoggedUser | undefined;
  forgotPassword: (email: string) => Promise<void>;
  login: (x: LoginCredentials) => Promise<string>;
  changePassword: (x: {
    password: string;
    token: string;
    email: string;
  }) => Promise<void>;
  logout: () => void;
}
export const AuthContext = React.createContext({} as AuthContextType);
const _isAuthenticated = () => !!localStorage.getItem("token");
const getLoggedUserFromLocalStorage = () => localStorage.getItem("loggedUser");

export const AuthProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(_isAuthenticated());
  const [loggedUser, setLoggedUser] = useState<LoggedUser | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const authRepo = useAuthRepository();

  useEffect(() => {
    setIsAuthenticated(_isAuthenticated());
    const loggedUserStr = getLoggedUserFromLocalStorage();
    if (loggedUserStr) {
      setLoggedUser(JSON.parse(loggedUserStr));
    }
  }, []);

  const login = async (x: LoginCredentials) => {
    const { token } = await authRepo.login(x);
    localStorage.setItem("token", token);
    const user = await authRepo.getUser(
      decode<{ user_id: number }>(token).user_id
    );
    localStorage.setItem("loggedUser", JSON.stringify(user));
    setLoggedUser(new LoggedUser(user));
    setIsAuthenticated(true);
    navigate("/main");
    return token;
  };

  const logout = () => {
    localStorage.setItem("token", "");
    setIsAuthenticated(false);
    navigate("/auth/login");
  };

  const forgotPassword = (email: string) => authRepo.forgotPassword(email);

  const changePassword = (x: {
    password: string;
    token: string;
    email: string;
  }) => authRepo.changePassword(x);

  const value: AuthContextType = {
    isAuthenticated,
    loggedUser,
    forgotPassword,
    login,
    changePassword,
    logout,
  };
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthService = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth debe estar dentro del proveedor AuthContext");
  }
  return authContext;
};
