import { Button } from "@vadiun/react-components";
import { useSuperMutation } from "@vadiun/react-hooks";
import { AuthLayout } from "app/auth/components";
import { useAuthService } from "app/auth/services";
import LoginIlustration from "assets/login.svg";
import Logo from "assets/logo.png";
import Logo2 from "assets/logo2.png";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Link } from "react-router-dom";
import * as Yup from "yup";

type FormSchema = {
  email: string;
  password: string;
};

const Schema: Yup.SchemaOf<FormSchema> = Yup.object({
  email: Yup.string()
    .email("El email es invalido")
    .required("El email es requerido"),
  password: Yup.string()
    .required("La contraseña es requerida")
    .min(4, "La contraseña debe tener como minimo 4 caracteres"),
});

export const LoginPage = () => {
  const authSrv = useAuthService();
  const initialValues: FormSchema = {
    email: "",
    password: "",
  };
  const loginMutation = useSuperMutation(authSrv.login);

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await loginMutation.mutate(value);

    formikHelpers.resetForm();
  };

  return (
    <AuthLayout
      ilustration={LoginIlustration}
      logo={Logo}
      title="Bienvenido a Las Leñas Traslados"
    >
      <div className="flex max-w-xl flex-col items-center">
        <img src={Logo2} className="mb-8 h-[100px] md:hidden" alt="logo" />
        <h1 className="my-4 text-center text-2xl font-bold">Login</h1>

        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={submit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                name="email"
                component={TextField}
              />
              <Field
                label="Contraseña"
                type="password"
                name="password"
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                component={TextField}
              />
              <Link
                to="/auth/forgot-password"
                className="text-primary-600 ml-auto cursor-pointer underline"
              >
                Olvidé mi contraseña
              </Link>
              <div className="my-8 w-full">
                <Button
                  color="blue"
                  className="w-full"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  INGRESAR
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};
