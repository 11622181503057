import React from 'react';
import { CreateUserForm } from './forms/create/CreateUserForm';
import { CreateUserFormType } from './forms/create/CreateUserFormType';

interface Props {
  create: (UserValues: CreateUserFormType) => Promise<void>;
}
export function UserCreate({ create }: Props) {
  return <CreateUserForm onSubmit={create} />;
}
