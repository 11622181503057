import { CircularProgress, Collapse } from "@mui/material";
import { Button } from "@vadiun/react-components";
import { useSnackbar } from "notistack";

import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { StaticMap } from "shared/components";
import useResponsiveBreakpoint from "shared/hooks/useResponsiveBreakpoint";
import { Location } from "../main/models/Reservation";
import { addressToString } from "../main/utils/addressToString";

export const ReservationsListComponent = ({
  children,
  loading,
  isEmpty,
  isEmptyMessage,
}: {
  children: React.ReactNode;
  loading?: boolean;
  isEmpty: boolean;
  isEmptyMessage: string;
}) =>
  loading ? (
    <div className="flex h-28 w-full items-center justify-center">
      <CircularProgress color="primary" size={30} />
    </div>
  ) : !isEmpty ? (
    <div className="grid w-full items-start gap-4 py-4 md:grid-cols-2">
      {children}
    </div>
  ) : (
    <div>
      <span className="text-typo-500 p-6">{isEmptyMessage}</span>
    </div>
  );

export const DotSeparatedLabels = ({ labels }: { labels: string[] }) => (
  <div className="flex w-full flex-wrap gap-1">
    {labels.map((label, index) => (
      <div className="flex items-center gap-1">
        <p className="text-typo-600 text-xs">{label}</p>
        {index !== labels.length - 1 && (
          <div className="bg-typo-300 h-1 w-1 flex-shrink-0 rounded-full" />
        )}
      </div>
    ))}
  </div>
);

export const ReservationsListComponentCard = ({
  id,
  fecha,
  precio,
  pasajeros,
  phones,
  requerimientos,
  itinerario,
  codigoChofer,
  to,
  copy_link
}: {
  id: number;
  fecha: string;
  precio: string;
  pasajeros: string[];
  phones?: string[];
  requerimientos: { label: string; value: string }[];
  itinerario: Location[];
  codigoChofer?: Number;
  to: string;
  copy_link?: boolean
}) => {
  const breakpoint = useResponsiveBreakpoint();
  const [showMore, setShowMore] = React.useState(false);
  const snackbar = useSnackbar();

  const mapSize =
    breakpoint === "xs" ? "350x250" : showMore ? "250x500" : "250x300";
  return (
    <div className="flex w-full flex-col overflow-hidden rounded-3xl border shadow-sm sm:flex-row">
      <div className="h-[200px] sm:h-auto sm:w-[250px]">
        <StaticMap
          size={mapSize}
          markers={itinerario.map((x, index) => ({
            lat: x.address.latitude,
            lng: x.address.longitude,
            label: String(index),
          }))}
        />
      </div>
      <div className="flex w-full flex-col items-start justify-around p-4">
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full justify-between">
            <p className="text-sm">{fecha}</p>
            <p className="text-typo-600 text-sm">ID: {id}</p>
          </div>
          <p className="text-typo-600 text-sm">${precio}</p>

          <div className="flex gap-1">
            <p className="text-typo-500 text-xs">Pasajeros:</p>
            <div className="ml-2">
              <DotSeparatedLabels labels={pasajeros} />
            </div>
          </div>
          <div className="flex gap-1">
            <p className="text-typo-500 text-xs">Número de teléfono:</p>
            <div className="ml-2">
              <DotSeparatedLabels labels={phones || ['-']} />
            </div>
          </div>
          <div className="flex gap-1">
            <p className="text-typo-500 text-xs">Origen:</p>
            <p className="text-typo-700 ml-2 text-xs">
              {addressToString(itinerario[0].address, { district: false })}
            </p>
          </div>
          <div className="flex gap-1">
            <p className="text-typo-500 text-xs">Destino:</p>
            <p className="text-typo-700 ml-2 text-xs">
              {addressToString(itinerario[itinerario.length - 1].address, {
                district: false,
              })}
            </p>
          </div>
          {copy_link && (
                <Button onClick={async() => {
                  await navigator.clipboard.writeText(
                    `https://portal.lltraslados.com.ar/#/public/reservations/${id}`
                  );
                  snackbar.enqueueSnackbar('Link copiado', {
                    variant: 'info'
                  });
                }}>
                  Copiar Link
              </Button>
              )}
          <Collapse in={showMore}>
            <div className="flex flex-col gap-2">
              {requerimientos.map((p) => (
                <div key={p.label} className="flex gap-1">
                  <p className="text-typo-500 text-xs">{p.label}: </p>
                  <p className="text-typo-700 text-xs">{p.value}</p>
                </div>
              ))}

              {codigoChofer && (
                <div className="flex gap-1">
                  <p className="text-typo-500 text-xs">Movil:</p>
                  <p className="text-typo-700 text-xs">{codigoChofer}</p>
                </div>
              )}
            </div>
          </Collapse>
          <div className="item-center flex justify-between">
            <button
              className="text-typo-500 flex items-center gap-1 p-1 text-xs"
              onClick={(ev) => {
                setShowMore((x) => !x);
                ev.stopPropagation();
                ev.preventDefault();
              }}
            >
              Ver {showMore ? "menos" : "más"}
              {showMore ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <Link to={to}>
              <Button variant="contained">Ver detalle</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
