import {
  ReservationComponent,
  ReservationComponentVoucher,
} from "app/reservation/components/ReservationComponent";
import { PageBody, PageContainer } from "layout/components";
import { useParams } from "react-router-dom";
import { IGeolocation } from "../main/models/Geolocation";
import {
  ReservationRepository,
  useReservationQuery,
  useReservationTrack,
} from "../main/services/reservationRepository";

import { Spinner } from "@vadiun/react-components";
import Default from "assets/default_location.png";
import moment from "moment";
import { ViajeTrasladoEstado } from "../main/models/Reservation";
import { getRequerimientos } from "../main/utils/getRequerimientos";

const repo = new ReservationRepository();

export const ReservationFinalizadaPage = () => {
  const params = useParams<{ id: string }>();
  const reservationQuery = useReservationQuery(Number(params.id));

  const { data: trackedTour } = useReservationTrack(Number(params.id));

  if (reservationQuery.data === undefined || trackedTour === undefined) {
    return (
      <PageContainer>
        <PageBody>
          <Spinner isVisible />
        </PageBody>
      </PageContainer>
    );
  }

  const mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const markers = reservationQuery.data?.locations.map((ubication) => ({
    lat: ubication.address.latitude,
    lng: ubication.address.longitude,
    icon: {
      scaledSize: new google.maps.Size(40, 40),
      url: Default,
    },
  }));

  const polyline = trackedTour?.map((location: IGeolocation) => ({
    lat: location.latitude,
    lng: location.longitude,
  }));

  const finalPrice =
    reservationQuery.data.final_prices?.transfer_price +
      reservationQuery.data.final_prices?.expenses_price ||
    reservationQuery.data.calculated_prices?.transfer_price +
      reservationQuery.data.calculated_prices?.expenses_price ||
    0;

  const horaEncurso = reservationQuery.data.states.find(
    (s) => s.type === ViajeTrasladoEstado.ANUNCIADO
  )?.datetime;
  const horaFinalizada = (
    reservationQuery.data.states.find(
      (s) => s.type === ViajeTrasladoEstado.AUDITORIA_PENDIENTE
    ) ||
    reservationQuery.data.states.find(
      (s) => s.type === ViajeTrasladoEstado.AUDITORIA_PENDIENTE
    )
  )?.datetime;
  const horaSolicitada = reservationQuery.data.reservationDatetime;

  const price =
    reservationQuery.data.final_prices?.transfer_price ??
    reservationQuery.data.estimated_prices.transfer_price;

  const kms =
    reservationQuery.data.final_prices?.kilometers ??
    reservationQuery.data.estimated_prices.kilometers;

  const rateType =
    reservationQuery.data.final_prices?.rate_type ??
    reservationQuery.data.estimated_prices.rate_type;

  const expenses =
    reservationQuery.data.final_prices?.expenses_price ??
    reservationQuery.data.estimated_prices.expenses_price;

  return (
    <PageContainer>
      <PageBody>
        <div className="flex w-full flex-col gap-4 sm:flex-row">
          <div className="w-full">
            <ReservationComponent.Card>
              <ReservationComponent.Resume
                date={reservationQuery.data.reservationDatetime ?? "-"}
                price={finalPrice}
                state={reservationQuery.data.state ?? "-"}
                markers={markers}
                mapStyles={mapStyles}
                polyline={{
                  path: polyline,
                }}
                route={
                  <div>
                    {reservationQuery.data.locations.map((ubication, index) => (
                      <ReservationComponent.Route
                        key={index}
                        index={index + 1}
                        address={`${ubication.address.street ?? "-"} ${
                          ubication.address.streetNumber ?? "-"
                        }, ${ubication.address.locality ?? "-"}`}
                      />
                    ))}
                  </div>
                }
              />
            </ReservationComponent.Card>
          </div>
          <ReservationComponent.Card>
            <ReservationComponentVoucher
              fecha={moment(reservationQuery.data.reservationDatetime).format(
                "DD/MM/YYYY"
              )}
              horaEnCurso={
                horaEncurso
                  ? moment(horaEncurso).format("HH:mm [hs.]")
                  : "Sin comenzar"
              }
              horaFinalizacion={
                horaFinalizada
                  ? moment(horaFinalizada).format("HH:mm [hs.]")
                  : "Sin finalizar"
              }
              horaSolicitada={
                horaSolicitada
                  ? moment(horaSolicitada).format("HH:mm [hs.]")
                  : "Sin solicitar"
              }
              pasajeros={reservationQuery.data.locations.flatMap(
                (x) => x.passengers
              )}
              requerimientos={getRequerimientos(
                reservationQuery.data.requirements
              )}
              tipoServicio={reservationQuery.data.serviceType}
            />
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full">
                      <thead className="border-b">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                          >
                            Items
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                          >
                            Cantidad
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                          >
                            Importe
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b">
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                            Cantidad de KM
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                            {reservationQuery.data?.final_prices ?
                            reservationQuery.data?.final_prices.kilometers :
                            reservationQuery.data.estimated_prices.kilometers}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                          {reservationQuery.data?.final_prices ?
                            reservationQuery.data?.final_prices.transfer_price :
                            reservationQuery.data.estimated_prices.transfer_price}
                          </td>
                        </tr>
                        <tr className="border-b">
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                            Gastos
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                            {}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                          {reservationQuery.data?.final_prices ?
                            reservationQuery.data?.final_prices.expenses_price :
                            reservationQuery.data.estimated_prices.expenses_price}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="rounded-md w-full flex justify-center text-center bg-primary-400 text-white p-2 mt-4">
                      El importe debe ser verificado por nuestro departamento de facturacion
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReservationComponent.Card>
        </div>
      </PageBody>
    </PageContainer>
  );
};
