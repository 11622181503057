import { Requirements } from "../models/Reservation";

export const getRequerimientos = (requirements: Requirements) => {
  const requerimientos = [];
  if (requirements.applicant) {
    requerimientos.push({
      label: "Solicitante",
      value: requirements.applicant.name,
    });
  }
  if (requirements.costCenter) {
    requerimientos.push({
      label: "Centro de costo",
      value: requirements.costCenter,
    });
  }
  if (requirements.sector) {
    requerimientos.push({
      label: "Sector",
      value: requirements.sector,
    });
  }
  if (requirements.authorized) {
    requerimientos.push({
      label: "Autorizado por",
      value: requirements.authorized.name,
    });
  }
  if (requirements.buyOrder) {
    requerimientos.push({
      label: "Orden de compra",
      value: requirements.buyOrder,
    });
  }
  if (requirements.employeeNumber) {
    requerimientos.push({
      label: "Número de empleado",
      value: requirements.employeeNumber,
    });
  }
  if (requirements.platformApplication) {
    requerimientos.push({
      label: "Aplicación de plataforma",
      value: requirements.platformApplication,
    });
  }
  if (requirements.tripNumber) {
    requerimientos.push({
      label: "Número de viaje",
      value: requirements.tripNumber,
    });
  }
  if (requirements.data1) {
    requerimientos.push({
      label: "Dato 1",
      value: requirements.data1,
    });
  }
  if (requirements.data2) {
    requerimientos.push({
      label: "Dato 2",
      value: requirements.data2,
    });
  }
  if (requirements.data3) {
    requerimientos.push({
      label: "Dato 3",
      value: requirements.data3,
    });
  }
  return requerimientos;
};
