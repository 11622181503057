import CarBlue from "assets/car-blue.png";
import CarGreen from "assets/car-green.png";

export const PublicReservationDriver = ({
  chofer_photo,
  chofer_name,
  vehicle_photo,
  vehicle_plate,
  vehicle_model
}: {
  chofer_photo: string;
  chofer_name: string;
  vehicle_photo: string;
  vehicle_plate: string;
  vehicle_model: string;
}) => (
  <div>
    <p className="mb-4 mt-8 text-sm text-gray-500">Chofer:</p>
    <div className="flex items-center gap-4">
      <img
        src={chofer_photo}
        alt=""
        className="h-14 w-14 rounded-md object-cover"
      />
      <p className="text-typo-700 ">{chofer_name}</p>
    </div>
    <p className="mb-4 mt-8 text-sm text-gray-500">Vehículo:</p>
    <div className="flex items-center gap-4">
      <img
        src={vehicle_photo}
        alt=""
        className="h-14 w-14 rounded-md object-cover"
      />
      <div className="flex flex-col">
        <p className="text-typo-700 text-sm ml-2">{vehicle_model}</p>
        <p className="text-typo-700 text-sm ml-2">{vehicle_plate}</p>
      </div>
    </div>
  </div>
);

export const PublicReservationItinerarioItem = ({
  index,
  address,
  lastIndex
}: {
  index: number;
  address: string;
  lastIndex: boolean;
}) => (
  <div className="flex items-center">
    {index === 0 ? (
      <>
        <img src={CarBlue} alt='' className="h-6 w-6" />
        <p className="text-typo-500 text-sm ml-1">Origen: </p>
      </>
    ) : lastIndex ?
    <>
      <img src={CarGreen} alt='' className="h-6 w-6" />
      <p className="text-typo-500 text-sm ml-1">Destino: </p>
    </> :
      <div className="bg-primary-400 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full  text-white">
        {index}
      </div>
    }
    <p className="text-typo-600 ml-3 text-sm">{address}</p>
  </div>
);
