import {
  ReservationComponent,
  ReservationComponentVoucher,
} from "app/reservation/components/ReservationComponent";
import { PageBody, PageContainer } from "layout/components";
import { useParams } from "react-router-dom";
import {
  ReservationRepository,
  useReservationQuery,
  useWorkingDriver,
} from "../main/services/reservationRepository";

import { Spinner } from "@vadiun/react-components";
import CarBlue from "assets/car-blue.png";
import Default from "assets/default_location.png";
import moment from "moment";
import { ViajeTrasladoEstado } from "../main/models/Reservation";
import { getRequerimientos } from "../main/utils/getRequerimientos";

const repo = new ReservationRepository();

export const ReservationEnViajePage = () => {
  const params = useParams<{ id: string }>();
  const reservationQuery = useReservationQuery(Number(params.id));

  const { data: workingDriver } = useWorkingDriver(
    Number(reservationQuery.data?.assigned_driver.code),
    {
      enabled: reservationQuery.data?.assigned_driver.code !== undefined,
      refetchInterval: 30 * 1000,
    }
  );

  if (reservationQuery.data === undefined || workingDriver === undefined) {
    return (
      <PageContainer>
        <PageBody>
          <Spinner isVisible />
        </PageBody>
      </PageContainer>
    );
  }

  const mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const markers = reservationQuery.data?.locations.map((ubication, index) => ({
    lat: ubication.address.latitude,
    lng: ubication.address.longitude,
    icon: {
      scaledSize: new google.maps.Size(40, 40),
      url: Default,
    },
  }));

  markers.push({
    lat: workingDriver[0].current_latitude,
    lng: workingDriver[0].current_longitude,
    icon: {
      scaledSize: new google.maps.Size(40, 40),
      url: CarBlue,
    },
  });

  const estimatedPrice =
    reservationQuery.data.estimated_prices?.transfer_price +
      reservationQuery.data.estimated_prices?.expenses_price || 0;

  const horaEncurso = reservationQuery.data.states.find(
    (s) => s.type === ViajeTrasladoEstado.ANUNCIADO
  )?.datetime;
  const horaSolicitada = reservationQuery.data.reservationDatetime;

  return (
    <PageContainer>
      <PageBody>
        <div className="flex w-full flex-col gap-4 sm:flex-row">
          <div className="w-full">
            <ReservationComponent.Card>
              <div>
                <ReservationComponent.Resume
                  date={reservationQuery.data.reservationDatetime ?? "-"}
                  price={estimatedPrice}
                  state={reservationQuery.data.state ?? "-"}
                  markers={markers}
                  mapStyles={mapStyles}
                  route={
                    <div>
                      {reservationQuery.data.locations.map(
                        (ubication, index) => (
                          <ReservationComponent.Route
                            key={index}
                            index={index + 1}
                            address={`${ubication.address.street ?? "-"} ${
                              ubication.address.streetNumber ?? "-"
                            }, ${ubication.address.locality ?? "-"}`}
                          />
                        )
                      )}
                    </div>
                  }
                />
              </div>
            </ReservationComponent.Card>
          </div>
          <ReservationComponent.Card>
            <ReservationComponentVoucher
              fecha={moment(reservationQuery.data.reservationDatetime).format(
                "DD/MM/YYYY"
              )}
              horaEnCurso={
                horaEncurso
                  ? moment(horaEncurso).format("HH:mm")
                  : "Sin comenzar"
              }
              horaFinalizacion={"Sin finalizar"}
              horaSolicitada={
                horaSolicitada
                  ? moment(horaSolicitada).format("HH:mm")
                  : "Sin solicitar"
              }
              pasajeros={reservationQuery.data.locations.flatMap(
                (x) => x.passengers
              )}
              requerimientos={getRequerimientos(
                reservationQuery.data.requirements
              )}
              tipoServicio={reservationQuery.data.serviceType}
            />
          </ReservationComponent.Card>
        </div>
      </PageBody>
    </PageContainer>
  );
};
