import { PaymentFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { useState } from "react";
import { RadioButton, RadioButtonItem } from "shared/components";

export const ReservationPaymentFrom: PaymentFormComponent = ({
  onSubmit,
  actions,
  initialValues,
}) => {
  const [isCashPayment, setIsCashPayment] = useState(initialValues);
  return (
    <div>
      <RadioButton value={isCashPayment} onChange={setIsCashPayment}>
        <div className="flex flex-col gap-4">
          <RadioButtonItem label="A cargo de la empresa" value={false} />
          <RadioButtonItem
            label={
              <div>
                <p className="text-left">Yo pago mi viaje</p>
                <p className="text-xs">
                  Efectivo o tarjeta de credito personal
                </p>
              </div>
            }
            value={true}
          />
        </div>
      </RadioButton>
      {actions(() => onSubmit(isCashPayment))}
    </div>
  );
};
