export class AuthorizedModel {
  constructor(
    public id: number,
    public name: string,
    public phone: string | null,
    public email: string | null
  ) {}
}

export const authorizedBuilder = {
  fromBackEnd: (authorized: any): AuthorizedModel => {
    return new AuthorizedModel(
      authorized.id,
      authorized.name,
      authorized.phone,
      authorized.email
    );
  },
};
