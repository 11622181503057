import { useAuthService } from "app/auth/services";
import { ClientBusinessModel } from "app/client/business/main/models/ClientBusiness";
import { useCreateReservationMutation } from "app/reservation/main/services/reservationService";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Query } from "react-query";
import { domicilioInitialValues } from "shared/components";
import {
  ReservationServiceTypeEnum,
  RESERVATION_SERVICE_TYPE,
} from "../../main/models/ReservationServiceType";

import { ReservationClientBusinessCreateType } from "../../main/services/reservationService/reservationCreateType";

export type Passanger = ReservationClientBusinessCreateType["passanger"];

export type ItineraryInfo = {
  itinerary: ReservationClientBusinessCreateType["itinerary"];
  reservationDateTime: Moment;
  observation: string;
};

export type ClientRequeriments = Query<ClientBusinessModel["requirements"]>;

export type Resume = {
  passanger: Passanger | undefined;
  reservationDateTime: string;
  cashPaymnet: boolean;
  serviceType: string;
  itinerary: ItineraryInfo["itinerary"];
};

export const useReservationClientPrivateCreate = () => {
  const authSrv = useAuthService();

  const createReservationMutation = useCreateReservationMutation();

  /**
   * States
   */
  const [step, setStep] = useState<
    "isPersonal" | "passangerInfo" | "itinerary" | "serviceType" | "resume"
  >("isPersonal");

  const [isPersonalReservation, setIsPersonalReservation] = useState(true);
  const [isCashPayment, setIsCashPayment] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState(
    ReservationServiceTypeEnum.EJECUTIVO
  );
  const [passanger, setPassanger] = useState<
    ReservationClientBusinessCreateType["passanger"]
  >({
    name: "",
    email: "",
    phone: "",
  });
  const [resume, setResume] = useState<Resume | undefined>(undefined);

  const [itineraryInfo, setItineraryInfo] = useState<ItineraryInfo>({
    reservationDateTime: moment(),
    itinerary: [{ address: domicilioInitialValues }],
    observation: "",
  });

  const onPaymentChange = (isCashPayment: boolean) => {
    setIsCashPayment(isCashPayment);
    setStep("isPersonal");
  };

  const onIsPersonalChange = (isPersonal: boolean) => {
    setIsPersonalReservation(isPersonal);
    if (isPersonal) {
      setPassanger({
        name: authSrv.loggedUser!.name,
        email: authSrv.loggedUser!.email,
        phone: passanger.phone,
      });
    }
    setStep("passangerInfo");
  };

  const onPassangerInfoChange = (passanger: Passanger) => {
    setPassanger(passanger);
    setStep("itinerary");
  };

  const onItineraryChange = (values: ItineraryInfo) => {
    setItineraryInfo(values);
    setStep("serviceType");
  };

  const onServiceTypeChange = (serviceType: ReservationServiceTypeEnum) => {
    setServiceType(serviceType);
    setResume({
      passanger: passanger,
      cashPaymnet: isCashPayment,
      serviceType,
      itinerary: itineraryInfo!.itinerary,
      reservationDateTime: itineraryInfo!.reservationDateTime.format(
        "DD/MM/YYYY HH:mm [hs.]"
      ),
    });
    setStep("resume");
  };

  const reset = () => {
    setStep("isPersonal");
    setIsPersonalReservation(true);
    setIsCashPayment(true);
    setServiceType(ReservationServiceTypeEnum.EJECUTIVO);
    setPassanger({
      name: "",
      email: "",
      phone: "",
    });
    setResume(undefined);
    setItineraryInfo({
      reservationDateTime: moment(),
      itinerary: [{ address: domicilioInitialValues }],
      observation: "",
    });
  };

  const onCreateReservation = async () => {
    await createReservationMutation.mutate({
      clientId: 1,
      itinerary: itineraryInfo!.itinerary,
      observation: itineraryInfo.observation,
      reservationDateTime: itineraryInfo!.reservationDateTime,
      passanger: passanger!,
      serviceType,
    });
    reset();
  };

  const onPassangerInfoPreviousStep = () => setStep("isPersonal");
  const onRequirementsPreviousStep = () => setStep("passangerInfo");

  const onItineraryPreviousStep = () => {
    setStep("passangerInfo");
  };
  const onServiceTypePreviousStep = () => {
    setStep("itinerary");
  };

  const onResumePreviousStep = () => setStep("serviceType");

  return {
    step,
    isCashPayment,
    isPersonalReservation,
    serviceType,
    serviceTypes: RESERVATION_SERVICE_TYPE,
    resume,
    createReservationMutation,
    itineraryInfo,
    passanger,
    onServiceTypeChange,
    onPassangerInfoChange,
    onItineraryChange,
    onIsPersonalChange,
    onCreateReservation,
    onPaymentChange,
    onPassangerInfoPreviousStep,
    onRequirementsPreviousStep,
    onItineraryPreviousStep,
    onServiceTypePreviousStep,
    onResumePreviousStep,
  };
};
