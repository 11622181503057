import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FaceIcon from "@mui/icons-material/Face";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import { useAuthService } from "app/auth/services/AuthService";
import React from "react";

interface Props {
  toggleSidebar: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "initial",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const Header = (props: Props) => {
  const authSrv = useAuthService();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const authService = useAuthService();
  const onOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <div
        className="flex flex-grow border-b-2 border-solid border-gray-100 bg-white shadow-lg"
        style={{ zIndex: 510 }}
      >
        <div className="text-primary flex h-12 flex-grow items-center justify-between px-3">
          <button
            className="mr-4 lg:hidden"
            onClick={(e) => props.toggleSidebar(e)}
          >
            <MenuIcon />
          </button>

          <div className="ml-auto flex items-center gap-3">
            <p className="text-sm text-gray-500">
              ¡Bienvenido/a{" "}
              <span className="text-gray-700">{authSrv.loggedUser?.name}</span>!
            </p>
            <div
              className="text-primary-500 cursor-pointer"
              onClick={onOpenProfileMenu}
            >
              <AccountCircleIcon style={{ fontSize: 30 }} />
            </div>
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledMenuItem onClick={authService.logout}>
          <ListItemIcon>
            <FaceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </StyledMenuItem>
      </Menu>
    </>
  );
};
