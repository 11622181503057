import { Button } from "@vadiun/react-components";
import { ItineraryFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DesktopDateTimePicker } from "formik-mui-lab";
import moment, { Moment } from "moment";
import {
  domicilioInitialValues,
  domicilioValidation,
  StaticMap,
} from "shared/components";
import FormikAddress from "shared/components/FormikAddress";
import * as Yup from "yup";

const validation = Yup.object({
  itinerary: Yup.array()
    .of(
      Yup.object({
        address: domicilioValidation,
      })
    )
    .min(2, "Ingrese al menos dos puntos"),
  reservationDateTime: Yup.mixed()
    .required()
    .test({
      test: (date: Moment) => date.isAfter(moment()),
      message: "La fecha debe ser posterior la fecha actual",
      name: "invalidDate",
    }),
  observation: Yup.string(),
});

export const ReservationItineraryForm: ItineraryFormComponent = ({
  onSubmit,
  actions,
  initialValues,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <div className="mb-8">
            <Field
              component={DesktopDateTimePicker}
              label="Fecha y hora"
              name="reservationDateTime"
              ampm={false}
              inputVariant="outlined"
              textField={{ fullWidth: true, margin: "normal" }}
              inputFormat="DD/MM/YYYY HH:mm"
              minDate={moment()}
            />
            <Field
              component={TextField}
              label="Agregar comentario"
              name="observation"
              inputVariant="outlined"
              fullWidth
              multiline
              maxRows={4}
              margin="normal"
            />
          </div>
          <FieldArray
            name={`itinerary`}
            render={(puntosArrayHelpers) => (
              <div className="mb-8">
                {formik.values.itinerary.map((point, index) => (
                  <div className="mb-8" key={index}>
                    <h2 className="text-typo-500 mb-4 text-sm">
                      Punto #{index + 1}
                    </h2>
                    <Field
                      name={`itinerary.${index}.address`}
                      label={`Domicilio`}
                      variant="outlined"
                      component={FormikAddress}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                ))}
                <Button
                  onClick={() =>
                    puntosArrayHelpers.push({ address: domicilioInitialValues })
                  }
                  variant="light"
                  className="w-full"
                  color="blue"
                >
                  Nuevo Punto
                </Button>
                <ErrorMessage name={`itinerary`}>
                  {(msg) => (
                    <p className="mt-4 text-sm text-red-500">
                      {typeof msg === "string" ? msg : ""}
                    </p>
                  )}
                </ErrorMessage>
              </div>
            )}
          />
          <StaticMap
            markers={formik.values.itinerary.map((punto) => ({
              lat: punto.address.latitud,
              lng: punto.address.longitud,
            }))}
            size="600x300"
          />

          <div className="my-8 w-full">{actions(formik.submitForm)}</div>
        </Form>
      )}
    </Formik>
  );
};
