import { Button } from "@vadiun/react-components";
import { ReservationIsPersonalForm } from "app/reservation/main/forms/isPersonal/ReservationIsPersonalForm";
import { ReservationItineraryForm } from "app/reservation/main/forms/itinerary/ReservationItineraryForm";
import { ReservationPassangerForm } from "app/reservation/main/forms/passanger/ReservationPassangerForm";
import { ReservationPaymentFrom } from "app/reservation/main/forms/payment/ReservationPaymentForm";
import { ReservationRequerimentsForm } from "app/reservation/main/forms/requeriments/ReservationRequerimentsForm";
import { ReservationServiceTypeFrom } from "app/reservation/main/forms/serviceType/ReservationServiceTypeForm";
import { PageBody, PageContainer } from "layout/components";
import React from "react";
import { Stepper } from "shared/components/Stepper/Stepper";
import { QueryData } from "shared/models/QueryData";
import { ReservationServiceTypeEnum } from "../../main/models/ReservationServiceType";
import { ReservationClientBusinessResumeComponent } from "../components/ReservationClientBusinessResumeComponent";
import {
  Authorizeds,
  ClientRequeriments,
  FormRequeriments,
  ItineraryInfo,
  Passanger,
  Resume,
  useReservationClientBusinessCreate,
} from "../hooks/useReservationClientBusinessCreate";

export const ReservationClientBusinessCreatePage = () => {
  const viewModel = useReservationClientBusinessCreate();
  const steps = {
    paymentType: (
      <ReservationPaymentFrom
        onSubmit={viewModel.onPaymentChange}
        initialValues={viewModel.isCashPayment}
        actions={(submit: Function) => (
          <Button
            className="mt-8 w-full"
            variant="contained"
            onClick={() => submit()}
          >
            Continuar
          </Button>
        )}
      />
    ),
    isPersonal: (
      <ReservationIsPersonalForm
        onSubmit={viewModel.onIsPersonalChange}
        initialValues={viewModel.isPersonalReservation}
        actions={(submit: Function) => (
          <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
            <Button
              className="w-full"
              variant="outlined"
              onClick={viewModel.onIsPersonalPreviousStep}
            >
              Atrás
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => submit()}
            >
              Continuar
            </Button>
          </div>
        )}
      />
    ),
    passangerInfo: (
      <ReservationPassangerForm
        onSubmit={viewModel.onPassangerInfoChange}
        initialValues={viewModel.passanger}
        actions={(submit: Function) => (
          <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
            <Button
              className="w-full"
              variant="outlined"
              onClick={viewModel.onPassangerInfoPreviousStep}
            >
              Atrás
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => submit()}
            >
              Continuar
            </Button>
          </div>
        )}
      />
    ),
    requirements: (
      <>
        {viewModel.clientBusinessRequerimentsQuery.data === undefined ? (
          "Cargando..."
        ) : (
          <ReservationRequerimentsForm
            onSubmit={viewModel.onRequirementsChange}
            initialValues={viewModel.requeriments}
            requeriments={viewModel.clientBusinessRequerimentsQuery.data}
            cost_centers={viewModel.cost_centers}
            authorizeds={viewModel.authorizedsQuery.data ?? []}
            actions={(submit: Function) => (
              <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
                <Button
                  className="w-full"
                  variant="outlined"
                  onClick={viewModel.onRequirementsPreviousStep}
                >
                  Atrás
                </Button>
                <Button
                  className="w-full"
                  variant="contained"
                  onClick={() => submit()}
                >
                  Continuar
                </Button>
              </div>
            )}
          />
        )}
      </>
    ),
    itinerary: (
      <ReservationItineraryForm
        onSubmit={viewModel.onItineraryChange}
        initialValues={viewModel.itineraryInfo}
        actions={(submit: Function) => (
          <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
            <Button
              className="w-full"
              variant="outlined"
              onClick={viewModel.onItineraryPreviousStep}
            >
              Atrás
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => submit()}
            >
              Continuar
            </Button>
          </div>
        )}
      />
    ),
    serviceType: (
      <ReservationServiceTypeFrom
        onSubmit={viewModel.onServiceTypeChange}
        initialValues={viewModel.serviceType}
        serviceTypes={viewModel.serviceTypes}
        actions={(submit: Function) => (
          <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
            <Button
              className="w-full"
              variant="outlined"
              onClick={viewModel.onServiceTypePreviousStep}
            >
              Atrás
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => submit()}
            >
              Continuar
            </Button>
          </div>
        )}
      />
    ),
    resume: (
      <ReservationClientBusinessResumeComponent
        resume={viewModel.resume!}
        actions={
          <div className="mt-8 flex flex-col-reverse gap-4 md:flex-row ">
            <Button
              className="w-full"
              variant="outlined"
              onClick={viewModel.onResumePreviousStep}
            >
              Atrás
            </Button>
            <Button
              variant="contained"
              className="w-full"
              onClick={viewModel.onCreateReservation}
              isLoading={viewModel.createReservationMutation.isLoading}
              disabled={viewModel.createReservationMutation.isLoading}
            >
              Reservar
            </Button>
          </div>
        }
      />
    ),
  };

  const { step } = viewModel;

  return (
    <PageContainer>
      <PageBody>
        <Stepper
          step={step}
          steps={
            <>
              <Stepper.Item
                title="Tipo de Pago"
                subtitle="¿Deseas pagar en efectivo?"
                step="paymentType"
                stepNumber={1}
                completed={
                  step === "isPersonal" ||
                  step === "passangerInfo" ||
                  step === "requirements" ||
                  step === "itinerary" ||
                  step === "serviceType" ||
                  step === "resume"
                }
              />
              <Stepper.Item
                title="Pasajero"
                subtitle="¿Quién va a viajar?"
                step="isPersonal"
                stepNumber={2}
                completed={
                  step === "passangerInfo" ||
                  step === "requirements" ||
                  step === "itinerary" ||
                  step === "serviceType" ||
                  step === "resume"
                }
              />
              <Stepper.Item
                title="Información pasajero"
                subtitle="Datos adicionales del pasajero"
                step="passangerInfo"
                stepNumber={3}
                completed={
                  step === "requirements" ||
                  step === "itinerary" ||
                  step === "serviceType" ||
                  step === "resume"
                }
              />
              <Stepper.Item
                title="Requerimientos"
                subtitle="Información solicitada por la empresa"
                step="requirements"
                stepNumber={4}
                completed={
                  step === "itinerary" ||
                  step === "serviceType" ||
                  step === "resume"
                }
              />
              <Stepper.Item
                title="Itinerario"
                subtitle="Información del viaje"
                step="itinerary"
                stepNumber={5}
                completed={step === "serviceType" || step === "resume"}
              />
              <Stepper.Item
                title="Servicio"
                subtitle="Tipo de servicio"
                step="serviceType"
                stepNumber={6}
                completed={step === "resume"}
              />
              <Stepper.Item
                title="Resumen"
                subtitle="Reserva solicitada"
                step="resume"
                stepNumber={7}
              />
            </>
          }
        >
          <div className="mt-8 md:mt-0 md:w-1/2">{steps[step]}</div>
        </Stepper>
      </PageBody>
    </PageContainer>
  );
};

export type PassangerFormComponent = React.FC<{
  onSubmit: (passanger: Passanger) => void;
  actions: (submit: Function) => React.ReactNode;
  initialValues: Passanger;
}>;

export type RequerimentsFormComponent = React.FC<{
  onSubmit: (req: FormRequeriments) => void;
  requeriments: QueryData<ClientRequeriments>;
  cost_centers?: { id: number, name: string }[];
  authorizeds: QueryData<Authorizeds>;
  actions: (submit: Function) => React.ReactNode;
  initialValues: FormRequeriments;
}>;

export type ItineraryFormComponent = React.FC<{
  onSubmit: (values: ItineraryInfo) => void;
  actions: (submit: Function) => React.ReactNode;
  initialValues: ItineraryInfo;
}>;

export type PaymentFormComponent = React.FC<{
  onSubmit: (isCashPayment: boolean) => void;
  actions: (submit: Function) => React.ReactNode;
  initialValues: boolean;
}>;

export type ServiceTypeFormComponent = React.FC<{
  onSubmit: (serviceType: ReservationServiceTypeEnum) => void;
  actions: (submit: Function) => React.ReactNode;
  serviceTypes: ReservationServiceTypeEnum[];
  initialValues: ReservationServiceTypeEnum;
}>;

export type IsPersonalFormComponent = React.FC<{
  onSubmit: (isPersonal: boolean) => void;
  actions: (submit: Function) => React.ReactNode;
  initialValues: boolean;
}>;

export type ResumeComponent = React.FC<{
  actions: React.ReactNode;
  resume: Resume;
}>;
