import React from "react";

interface Props {
  children: React.ReactNode;
}

export const CardHeader = ({ children }: Props) => {
  return (
    <div className="flex items-center justify-between border-b-2 border-solid border-gray-100 py-4 px-8">
      {children}
    </div>
  );
};
