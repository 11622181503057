import { Passanger } from "app/reservation/clientBusiness/hooks/useReservationClientBusinessCreate";
import { PassangerFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";

const ReservationPassangerValidation: Yup.SchemaOf<Passanger> = Yup.object({
  email: Yup.string(),
  name: Yup.string().required("El nombre es requerido"),
  phone: Yup.string(),
});

export const ReservationPassangerForm: PassangerFormComponent = ({
  onSubmit,
  actions,
  initialValues,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReservationPassangerValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Field
            label="Nombre *"
            variant="outlined"
            margin="normal"
            fullWidth
            className="bg-white"
            name="name"
            component={TextField}
          />
          <Field
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            className="bg-white"
            name="email"
            component={TextField}
          />
          <Field
            label="Teléfono"
            variant="outlined"
            margin="normal"
            fullWidth
            className="bg-white"
            name="phone"
            component={TextField}
          />

          <div className="my-8 w-full">{actions(formik.submitForm)}</div>
        </Form>
      )}
    </Formik>
  );
};
