import { Address } from "../models/Reservation";

export const addressToString = (
  address: Address,
  options = {
    district: true,
  }
) => {
  let value = `${address.street}`;
  if (address.streetNumber) {
    value = value.concat(` ${address.streetNumber}`);
  }
  if (address.locality) {
    value = value.concat(`, ${address.locality}`);
  }
  if (address.district && options.district) {
    value = value.concat(`, ${address.district}`);
  }
  return value;
};
