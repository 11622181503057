import { Card } from "shared/components";
import { useSuperQuery } from "@vadiun/react-hooks";
import { PageBody, PageContainer } from "layout/components";
import { useAuthService } from '../auth/services/AuthService';
import { CreateUserFormType } from "./components/forms/create/CreateUserFormType";
import { UserCreate } from "./components/UserCreate";
import useClientBusiness from "./hooks/useClientBusiness";
import { Button } from "@vadiun/react-components";
import { UserListTable } from "./components/UserListTable";
import { useNavigate } from 'react-router-dom';

export const UserListPage = () => {
 const repo = useClientBusiness()
 const navigate = useNavigate()

 const usersQuery = useSuperQuery(['users'], repo.getUsers, {
    showSpinner: true
  });

  return (
    <PageContainer>
      <PageBody>
        <Card 
          title='Solicitantes' 
          subtitle='Listado de solicitantes' 
          toolbar={
            <Button variant="light" onClick={() => navigate('/main/users/create')}>
              Crear Solicitante
            </Button>
          }
        >
          <UserListTable 
            users={usersQuery.data || []}
          />
        </Card>
      </PageBody>
    </PageContainer>
  );
};
