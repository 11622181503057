import { Card } from "shared/components";
import { useSuperMutation } from "@vadiun/react-hooks";
import { PageBody, PageContainer } from "layout/components";
import { useAuthService } from '../auth/services/AuthService';
import { EditUserFormType } from "./components/forms/edit/EditUserFormType";
import { UserEdit } from "./components/UserEdit";
import useClientBusiness from "./hooks/useClientBusiness";
import { Button } from "@vadiun/react-components";
import { useNavigate } from 'react-router-dom';

export const EditUserPage = () => {
 const repo = useClientBusiness()
 const navigate = useNavigate()

 const updateUserMutation = useSuperMutation(repo.updateUser);

 async function edit(userValues: EditUserFormType) {
  await updateUserMutation.mutateAsync(userValues)
  .then(() => navigate('/main/users/list'))
 }

  return (
    <PageContainer>
      <PageBody>
        <Card 
          title='Solicitantes' 
          subtitle='Editar solicitante' 
          toolbar={
            <Button 
              variant="light"
              onClick={() => navigate('/main/users/list')} 
            >
              Volver
            </Button>
          }
        >
          <div className="flex w-full p-8 flex-col gap-4 sm:flex-row">
            <UserEdit edit={edit} />
          </div>
        </Card>
      </PageBody>
    </PageContainer>
  );
};
