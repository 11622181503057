import { createDomicilioStreetText } from "app/main/address/models/DomicilioGoogle";
import React from "react";
import { ResumeComponent } from "../pages/ReservationClientBusinessCreatePage";

export const ReservationClientBusinessResumeComponent: ResumeComponent = ({
  resume,
  actions,
}) => {
  const { passanger } = resume;
  return (
    <div>
      <div className="rounded-lg border-2 border-dashed border-gray-200 bg-gray-50 p-8">
        <div className="flex justify-between">
          {passanger ? (
            <div>
              <h1 className="font-bold">{passanger.name}</h1>
              <p className="text-typo-800 text-xs">{passanger.email}</p>
              <p className="text-typo-800 text-xs">{passanger.phone}</p>
            </div>
          ) : (
            <h1 className="font-bold">Viaje Personal</h1>
          )}
          <div className="flex flex-col items-end gap-2">
            <div className="bg-primary-500 rounded-full py-2 px-4 text-sm text-white">
              {resume.serviceType}
            </div>
          </div>
        </div>

        <p className="mt-6 mb-4 text-sm">{resume.reservationDateTime}</p>

        <ul className="list-inside list-decimal">
          {resume.itinerary.map((point, index) => (
            <li className="text-sm" key={index}>
              {createDomicilioStreetText(point.address)},{" "}
              {point.address.localidad}
            </li>
          ))}
        </ul>
        {resume.requirements.authorizedId ||
        resume.requirements.buyOrder ||
        resume.requirements.costCenter ||
        resume.requirements.employeeNumber ||
        resume.requirements.platformApplication ||
        resume.requirements.sector ||
        resume.requirements.tripNumber ? (
            <h2 className=" text-typo-800 mt-8 mb-2 text-sm underline">
            Requerimientos
            </h2>
          ) : null}

        <ul className="text-typo-800 list-inside list-disc">
          {resume.requirements.authorizedId ? (
            <li className="text-sm">
              Autorizado: {resume.requirements.authorizedName}
            </li>
          ) : null}
          {resume.requirements.buyOrder ? (
            <li className="text-sm">
              Orden de pago: {resume.requirements.buyOrder}
            </li>
          ) : null}
          {resume.requirements.costCenter ? (
            <li className="text-sm">
              Centro de costos: {resume.requirements.costCenter}
            </li>
          ) : null}
          {resume.requirements.employeeNumber ? (
            <li className="text-sm">
              Nro. empleado: {resume.requirements.employeeNumber}
            </li>
          ) : null}
          {resume.requirements.platformApplication ? (
            <li className="text-sm">
              Solicitud de plataforma: {resume.requirements.platformApplication}
            </li>
          ) : null}
          {resume.requirements.sector ? (
            <li className="text-sm">Sector: {resume.requirements.sector}</li>
          ) : null}
          {resume.requirements.tripNumber ? (
            <li className="text-sm">
              Nro. viaje: {resume.requirements.tripNumber}
            </li>
          ) : null}
        </ul>
      </div>
      <div className="mt-8">{actions}</div>
    </div>
  );
};
