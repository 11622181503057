export interface IGeolocation {
    datetime: string;
    latitude: number;
    longitude: number;
}

export class Geolocation {
    datetime: string;
    latitude: number;
    longitude: number;
    
  constructor(args: IGeolocation) {
    this.datetime = args.datetime;
    this.latitude = args.latitude;           
    this.longitude = args.longitude;           
  }
}