import { useSuperQuery } from "@vadiun/react-hooks";
import { httpClient } from "shared/services";
import { Geolocation, IGeolocation } from "../models/Geolocation";
import { PublicReservation } from "../models/PublicReservation";
import { Reservation } from "../models/Reservation";

export enum ReservationStatus {
  PENDIENTE = "Pendiente",
  EN_VIAJE = "En viaje",
  FINALIZADA = "Finalizada",
}

export interface PaginatedResponse<T> {
  data: T[];
  total: number;
}

export const getReservationsMapper = (x: any): Reservation =>
  new Reservation(x);

export const getPublicReservationMapper = (x: any): PublicReservation => x;

export const getGeolocationMapper = (x: IGeolocation): Geolocation =>
  new Geolocation(x);

export class ReservationRepository {
  keys = {
    reservationsByStatus: (status: ReservationStatus, page: number) => [
      "reservations",
      { status, page },
    ],
    working_driver: (driver_code: number) => ["working_driver", driver_code],
    tracked_tour: (id: number) => ["tracked_tour", id],
    public_reservation: (id: string) => ["public_reservation", id],
    reservationById: (id: number) => ["reservations", { id }],
  };

  getReservationsByStatus = async (status: ReservationStatus, page: number) => {
    const params = new URLSearchParams();
    if (status === ReservationStatus.EN_VIAJE) {
      params.append("filter[viaje_en_curso]", "1");
    }
    if (status === ReservationStatus.FINALIZADA) {
      params.append("filter[viaje_realizado]", "1");
    }
    if (status === ReservationStatus.PENDIENTE) {
      params.append("filter[viaje_sin_iniciar]", "1");
    }
    const res = await httpClient.get<{
      data: any[];
      meta: { total: number; current_page: number };
    }>(`portal/reservas?${params}&page=${page}`);
    return {
      total: res.meta?.total,
      current_page: res.meta?.current_page,
      data: res.data?.map(getReservationsMapper),
    }; /* as PaginatedResponse<Reservation[]> */
    /* return res.map(getReservationsMapper); */
  };

  getWorkingDriver = async (driver_code: number) => {
    const res = await httpClient.get(
      `drivers/working?driver_code=${driver_code}`
    );
    return res;
  };

  getTrackedTour = async (id: number) => {
    const res = await httpClient.get(
      `transfer-reservations/${id}/geolocations`
    );
    return res.map(getGeolocationMapper);
  };

  publicFind = async (id: string) => {
    const res = await httpClient.get(
      `public/reservas/${encodeURIComponent(id)}`
    );
    return getPublicReservationMapper(res);
  };

  find = async (id: number) => {
    const res = await httpClient.get(`portal/reservas/${id}`);
    return getReservationsMapper(res);
  };
}

const repo = new ReservationRepository();

export const useReservationsByStatusQuery = (
  status: ReservationStatus,
  page: number
) =>
  useSuperQuery(
    repo.keys.reservationsByStatus(status, page),
    () => repo.getReservationsByStatus(status, page),
    { staleTime: 10 * 1000 }
  );

export const usePublicReservationQuery = (id: string) => {
  return useSuperQuery(
    repo.keys.public_reservation(id),
    () => repo.publicFind(id),
    {
      refetchInterval: 10 * 1000,
      showErrorMessage: false,
    }
  );
};

export const useReservationQuery = (id: number) =>
  useSuperQuery(repo.keys.reservationById(id), () => repo.find(id));

export const useWorkingDriver = (
  code: number,
  options: { enabled: boolean; refetchInterval: number }
) =>
  useSuperQuery(
    repo.keys.working_driver(code),
    () => repo.getWorkingDriver(code),
    options
  );

export const useReservationTrack = (id: number) =>
  useSuperQuery(repo.keys.tracked_tour(id), () => repo.getTrackedTour(id));
