interface IClientBusinessRequirementsModel {
  needsCostCenter: boolean;
  needsSector: boolean;
  needsAuthorized: boolean;
  needsBuyOrder: boolean;
  needsEmployeeNumber: boolean;
  needsPlatformApplication: boolean;
  needsTripNumber: boolean;
  needsData1: boolean;
  needsData2: boolean;
  needsData3: boolean;
}

export class ClientBusinessRequirementsModel {
  needsCostCenter: boolean;
  needsSector: boolean;
  needsAuthorized: boolean;
  needsBuyOrder: boolean;
  needsEmployeeNumber: boolean;
  needsPlatformApplication: boolean;
  needsTripNumber: boolean;
  needsData1: boolean;
  needsData2: boolean;
  needsData3: boolean;
  constructor(args: IClientBusinessRequirementsModel) {
    this.needsCostCenter = args.needsCostCenter;
    this.needsSector = args.needsSector;
    this.needsAuthorized = args.needsAuthorized;
    this.needsBuyOrder = args.needsBuyOrder;
    this.needsEmployeeNumber = args.needsEmployeeNumber;
    this.needsPlatformApplication = args.needsPlatformApplication;
    this.needsTripNumber = args.needsTripNumber;
    this.needsData1 = args.needsData1;
    this.needsData2 = args.needsData2;
    this.needsData3 = args.needsData3;
  }
}

export class ClientBusinessModel {
  constructor(
    public id: number,
    public business_client_cost_centers: { id: number, name: string }[],
    public requirements: ClientBusinessRequirementsModel
  ) {}
}

export const clientBusinessBuilder = {
  fromBackEnd: (client: any): ClientBusinessModel => {
    const requirements = new ClientBusinessRequirementsModel(
      client.reservationRequirement
    );
    return new ClientBusinessModel(client.id, client.business_client_cost_centers, requirements);
  },
};
