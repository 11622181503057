import moment from "moment";
import { DynamicMap } from "shared/components";
import { BackNavigation } from "shared/components/BackNavigation";

export const ReservationComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => <div className="flex w-full flex-col">{children}</div>;

ReservationComponent.Card = ({ children }: { children: React.ReactNode }) => (
  <div className="rounded-md bg-white pb-8 px-8 pt-4">{children}</div>
);

ReservationComponent.Resume = ({
  date,
  price,
  state,
  markers,
  mapStyles,
  polyline,
  route,
  tab
}: {
  date: string;
  tab?: string;
  price: number;
  state: string;
  markers: any[];
  polyline?: {
    style?: "primary" | "secondary";
    path: { lat: number; lng: number }[];
  };
  mapStyles: google.maps.MapTypeStyle[];
  route: JSX.Element;
}) => {
  return (
    <div className="flex w-full flex-col">
      <div className="text-typo-700 mb-3 flex items-center justify-start">
        <BackNavigation />
      </div>
      <div className="text-typo-700 mb-3 flex items-center justify-between gap-8 ">
        <p className="">{moment(date).format("DD/MM/YYYY HH:mm [hs.]")}</p>
        <p className="">${price}</p>
      </div>
      <p className="text-typo-700 ml-auto ">{state}</p>
      <div className="mt-4 grid w-full">
        <DynamicMap
          markers={markers}
          containerElement={
            <div
              className="overflow-hidden rounded-md"
              style={{ height: `300px`, width: "100%" }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
          polyline={polyline ?? { path: [] }}
        />
        <div className="mt-4">{route}</div>
      </div>
    </div>
  );
};

ReservationComponent.Driver = ({
  chofer_id,
  chofer_photo,
  chofer_name,
  vehicle_photo,
  vehicle_model,
  vehicle_plate,
}: {
  chofer_id: number;
  chofer_photo: string;
  chofer_name: string;
  vehicle_photo: string;
  vehicle_model: string;
  vehicle_plate: string;
}) => (
  <div className="min-w-[300px]">
    {chofer_id ? (
      <div className="w-full">
        <div
          className="relative flex flex-col items-center rounded-t-md border-b border-gray-100 pb-4 pt-6"
          style={{
            backgroundImage:
              "linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% )",
          }}
        >
          <img
            src={chofer_photo}
            alt=""
            className="h-32 w-32 rounded-full object-cover object-center"
          />
          <p className="mt-2 font-bold text-white">{chofer_name}</p>
        </div>
        <div className="flex gap-4 p-6">
          <img
            src={vehicle_photo}
            alt=""
            className="h-14 w-14 rounded-md object-cover"
          />
          <div>
            <p className="text-typo-700 ">{vehicle_plate}</p>
            <p className="text-typo-400 text-sm">{vehicle_model}</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="m-8">
        <span className="text-typo-600 text-center text-[14px]">
          Aún no fue asignado un chofer
        </span>
      </div>
    )}
  </div>
);

ReservationComponent.Route = ({
  index,
  address,
}: {
  index: number;
  address: string;
}) => (
  <div className="my-2 flex items-center">
    <div className="bg-primary-400 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full  text-white">
      {index}
    </div>
    <p className="text-typo-600 ml-3 text-sm">{address}</p>
  </div>
);

export const ReservationComponentVoucher = ({
  requerimientos,
  pasajeros,
  horaSolicitada,
  horaEnCurso,
  horaFinalizacion,
  fecha,
  tipoServicio,
}: {
  requerimientos: { label: string; value: string }[];
  pasajeros: { name: string; email: string | null; phone: string | null }[];
  horaSolicitada: string;
  horaEnCurso: string;
  horaFinalizacion: string;
  fecha: string;
  tipoServicio: string;
}) => (
  <div className="min-w-[300px]">
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <p className="text-typo-500 text-sm">Fecha del viaje: </p>
        <p className="text-typo-700 text-sm">{fecha}</p>
      </div>
      <div className="flex gap-1">
        <p className="text-typo-500 text-sm">Tipo de servicio: </p>
        <p className="text-typo-700 text-sm">{tipoServicio}</p>
      </div>
      <div className="">
        <p className="text-typo-500 text-sm">Pasajeros:</p>
        <div className="ml-4">
          {pasajeros.map((p) => (
            <div key={p.name}>
              <div className="flex gap-1">
                <p className="text-typo-500 text-sm">Nombre: </p>
                <p className="text-typo-700 text-sm">{p.name}</p>
              </div>
              <div className="flex gap-1">
                <p className="text-typo-500 text-sm">Email: </p>
                <p className="text-typo-700 text-sm">{p.email}</p>
              </div>
              <div className="flex gap-1">
                <p className="text-typo-500 text-sm">Teléfono: </p>
                <p className="text-typo-700 text-sm">{p.phone}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {requerimientos.map((p) => (
        <div key={p.label} className="flex gap-1">
          <p className="text-typo-500 text-sm">{p.label}: </p>
          <p className="text-typo-700 text-sm">{p.value}</p>
        </div>
      ))}

      <div className="flex gap-1">
        <p className="text-typo-500 text-sm">Hora solicitada: </p>
        <p className="text-typo-700 text-sm">{horaSolicitada}</p>
      </div>
      <div className="flex gap-1">
        <p className="text-typo-500 text-sm">Hora en curso: </p>
        <p className="text-typo-700 text-sm">{horaEnCurso}</p>
      </div>
      <div className="flex gap-1">
        <p className="text-typo-500 text-sm">Hora finalización: </p>
        <p className="text-typo-700 text-sm">{horaFinalizacion}</p>
      </div>
    </div>
  </div>
);
