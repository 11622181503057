import { httpClient } from "shared/services";
import { Solicitante } from '../main/models/Solicitante';

  export const getSolicitantesMapper = (x: any): Solicitante => new Solicitante(x);

  const useClientBusiness = () => {  

    const addUser = (values: { email: string; name: string; }) =>
      httpClient.post(`portal/applicants`, values);


    const updateUser = (user: {
      name: string;
      email: string;
      roles?: string[];
      id?: number;
    }) => {
      return httpClient.put('users/' + user.id, {
        email: user.email,
        name: user.name,
        roles: user.roles
      });
    };  

    const getUsers = async() => {
      const response = await httpClient.get(`portal/applicants`);
      return response.map(getSolicitantesMapper)
    }
  
    return {
      addUser,
      updateUser,
      getUsers
    };
  };
  
  export default useClientBusiness;
