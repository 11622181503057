import { ReservationComponent } from "app/reservation/components/ReservationComponent";
import { PageBody, PageContainer } from "layout/components";
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  ReservationRepository,
  useReservationQuery,
} from "../main/services/reservationRepository";

import { Button, Spinner } from "@vadiun/react-components";
import Default from "assets/default_location.png";

const repo = new ReservationRepository();

export const ReservationPendientePage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate()
  const reservationQuery = useReservationQuery(Number(params.id));

  if (reservationQuery.data === undefined) {
    return (
      <PageContainer>
        <PageBody>
          <Spinner isVisible />
        </PageBody>
      </PageContainer>
    );
  }

  const mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const markers = reservationQuery.data?.locations.map((ubication) => ({
    lat: ubication.address.latitude,
    lng: ubication.address.longitude,
    icon: {
      scaledSize: new google.maps.Size(40, 40),
      url: Default,
    },
  }));

  const estimatedPrice =
    reservationQuery.data.estimated_prices?.transfer_price +
      reservationQuery.data.estimated_prices?.expenses_price || 0;

  return (
    <PageContainer>
      <PageBody>
        <div className="flex w-full flex-col gap-4 sm:flex-row">
          <div className="w-full">
            <ReservationComponent.Card>
              <ReservationComponent.Resume
                date={reservationQuery.data.reservationDatetime ?? "-"}
                price={estimatedPrice}
                state={reservationQuery.data.state ?? "-"}
                markers={markers}
                mapStyles={mapStyles}
                route={
                  <div>
                    {reservationQuery.data.locations.map((ubication, index) => (
                      <ReservationComponent.Route
                        key={index}
                        index={index + 1}
                        address={`${ubication.address.street ?? "-"} ${
                          ubication.address.streetNumber ?? "-"
                        }, ${ubication.address.locality ?? "-"}`}
                      />
                    ))}
                  </div>
                }
              />
              <div
                className="flex justify-end pt-8"
              >
                <Button 
                  variant="light" 
                  onClick={() => navigate(`/main/reservations/edit/${params.id}`)}
                >
                  Editar
                </Button>              
              </div>
            </ReservationComponent.Card>
          </div>
          <ReservationComponent.Card>
            <ReservationComponent.Driver
              chofer_id={reservationQuery.data.assigned_driver?.id}
              chofer_photo={reservationQuery.data.assigned_driver?.photo ?? "-"}
              chofer_name={reservationQuery.data.assigned_driver?.name ?? "-"}
              vehicle_photo={
                reservationQuery.data.assigned_driver?.vehicle.photo ?? "-"
              }
              vehicle_model={`${
                reservationQuery.data.assigned_driver?.vehicle?.model?.brand
                  ?.name ?? "-"
              } ${
                reservationQuery.data.assigned_driver?.vehicle?.model?.name ??
                "-"
              }`}
              vehicle_plate={
                reservationQuery.data.assigned_driver?.vehicle.patent ?? "-"
              }
            />
          </ReservationComponent.Card>
        </div>
      </PageBody>
    </PageContainer>
  );
};
