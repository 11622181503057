interface IReservation {
  id: number;
  client: Client;
  entryChannel: string;
  platformId: null;
  hasPriority: boolean;
  serviceType: string;
  coordinationObservation: null;
  reservationDatetime: string;
  advanceHours: number;
  clientType: string;
  requirements: Requirements;
  locations: Location[];
  state: string;
  deviation_observation: string;
  arrival_notification_channel: string;
  state_datetime: string;
  pre_assigned_driver: null;
  assigned_driver: AssignedDriver;
  has_out_site: boolean;
  estimated_prices: Prices;
  calculated_prices: Prices;
  final_prices: Prices;
  setback: null;
  states: State[];
  pay_with_cash: boolean;
}

export interface AssignedDriver {
  id: number;
  code: number;
  name: string;
  photo: string;
  vehicle: Vehicle;
  shift_address: Address;
  category: string;
  hasPosnet: boolean;
  isDeleted: boolean;
  opened_shift: boolean;
  billers: Biller[];
}

export interface Biller {
  id: number;
  name: string;
  mail: string;
  cuit: number;
  bankName: string;
  accountOwnerName: string;
  cbu: string;
  accountNumber: string;
  alias: string;
  isDeleted: boolean;
  photo: string;
  monotributo_start_date: string;
  ingresos_brutos_start_date: string;
}

export interface Address {
  street: string;
  streetNumber: number;
  floor: null | string;
  apartment: null;
  locality: string;
  district: string;
  province: string;
  latitude: number;
  longitude: number;
}

export interface Vehicle {
  id: number;
  photo: string;
  model: Model;
  patent: string;
  category: string;
  has_downtown_permit: boolean;
  isDeleted: boolean;
}

export interface Model {
  id: number;
  name: string;
  brand?: Model;
  isDeleted: boolean;
}

export interface Prices {
  waiting_minutes_price: number;
  waiting_minutes: number;
  kilometers: number;
  out_site_price: number;
  km_price: number;
  night_plus_price: number;
  rate_type: string;
  type: number;
  fixed_price_name: null;
  expenses_price: number;
  administrative_commission: number;
  driver_commission: number;
  observation: null;
  transfer_price: number;
  driver_price: number;
}

export interface Client {
  id: number;
  name: string;
  hasPriority: boolean;
  type: string;
}

export interface Location {
  observation: null;
  minutesWaiting: number;
  address: Address;
  passengers: Passenger[];
}

export interface Passenger {
  id: number;
  name: string;
  phone: string;
  email: null;
  hasPriority: boolean;
  dni: null;
  nationality: null;
}

export interface Requirements {
  costCenter: null | string;
  applicant: null | {
    id: number;
    name: string;
    email: null | string;
    phone: null | string;
  };
  authorized: null | {
    id: number;
    name: string;
    email: null | string;
    phone: null | string;
  };
  buyOrder: null | string;
  employeeNumber: null | string;
  platformApplication: null | string;
  sector: null | string;
  tripNumber: null | string;
  data1: string | null;
  data2: string | null;
  data3: string | null;
}

export interface Applicant {
  id: number;
  name: string;
  phone: null;
  email: null;
}

export enum ViajeTrasladoEstado {
  PENDIENTE = "Pendiente",
  CREADA = "Creada",
  ASIGNADA = "Asignada",
  ACEPTADA = "Aceptada",
  EN_VIAJE_A_ORIGEN = "En viaje a origen",
  ANUNCIADO = "Anunciado",
  EN_VIAJE_A_DESTINO = "En viaje a destino",
  FINALIZADO = "Viaje Finalizado",
  AUDITORIA_PENDIENTE = "Auditoria pendiente",
  AUDIATADA_MANUALMENTE = "Auditada manualmente",
  AUDITADA_AUTOMATICAMENTE = "Auditada automaticamente",
  CANCELADA = "Cancelado",
  LIQUIDADA = "Liquidado",
  FACTURADA = "Facturado",
}
export const VIAJE_TRASLADO_ESTADOS = Object.values(ViajeTrasladoEstado);

export interface State {
  type: ViajeTrasladoEstado;
  datetime: string;
  user: User;
  observation: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  roles: string[];
  isDeleted: boolean;
}

export class Reservation {
  id: number;
  client: Client;
  entryChannel: string;
  platformId: null;
  hasPriority: boolean;
  serviceType: string;
  coordinationObservation: null;
  reservationDatetime: string;
  advanceHours: number;
  clientType: string;
  requirements: Requirements;
  locations: Location[];
  state: string;
  deviation_observation: string;
  arrival_notification_channel: string;
  state_datetime: string;
  pre_assigned_driver: null;
  assigned_driver: AssignedDriver;
  has_out_site: boolean;
  estimated_prices: Prices;
  calculated_prices: Prices;
  final_prices: Prices;
  setback: null;
  states: State[];
  pay_with_cash: boolean;

  constructor(args: IReservation) {
    this.id = args.id;
    this.client = args.client;
    this.entryChannel = args.entryChannel;
    this.platformId = args.platformId;
    this.hasPriority = args.hasPriority;
    this.serviceType = args.serviceType;
    this.coordinationObservation = args.coordinationObservation;
    this.reservationDatetime = args.reservationDatetime;
    this.advanceHours = args.advanceHours;
    this.clientType = args.clientType;
    this.requirements = args.requirements;
    this.locations = args.locations;
    this.state = args.state;
    this.deviation_observation = args.deviation_observation;
    this.arrival_notification_channel = args.arrival_notification_channel;
    this.state_datetime = args.state_datetime;
    this.pre_assigned_driver = args.pre_assigned_driver;
    this.assigned_driver = args.assigned_driver;
    this.has_out_site = args.has_out_site;
    this.estimated_prices = args.estimated_prices;
    this.calculated_prices = args.calculated_prices;
    this.final_prices = args.final_prices;
    this.setback = args.setback;
    this.states = args.states;
    this.pay_with_cash = args.pay_with_cash;
  }
}
