import { Card } from "shared/components";
import { useSuperMutation } from "@vadiun/react-hooks";
import { PageBody, PageContainer } from "layout/components";
import { useAuthService } from '../auth/services/AuthService';
import { CreateUserFormType } from "./components/forms/create/CreateUserFormType";
import { UserCreate } from "./components/UserCreate";
import useClientBusiness from "./hooks/useClientBusiness";
import { Button } from "@vadiun/react-components";
import { useNavigate } from 'react-router-dom';

export const CreateUserPage = () => {
 const repo = useClientBusiness()
 const navigate = useNavigate()

 const addUserMutation = useSuperMutation(repo.addUser);

 async function create(userValues: CreateUserFormType) {
    await addUserMutation.mutate(userValues);
  }

  return (
    <PageContainer>
      <PageBody>
        <Card 
          title='Solicitantes' 
          subtitle='Nuevo solicitante' 
          toolbar={
            <Button 
              variant="light"
              onClick={() => navigate('/main/users/list')} 
            >
              Volver
            </Button>
          }
        >
          <div className="flex w-full p-8 flex-col gap-4 sm:flex-row">
            <UserCreate create={create} />
          </div>
        </Card>
      </PageBody>
    </PageContainer>
  );
};
