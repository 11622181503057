import { IsPersonalFormComponent } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { useEffect, useState } from "react";
import { RadioButton, RadioButtonItem } from "shared/components";

export const ReservationIsPersonalForm: IsPersonalFormComponent = ({
  onSubmit,
  actions,
  initialValues,
}) => {
  const [isPersonalReservation, setIsPersonalReservation] =
    useState(initialValues);

  return (
    <div>
      <RadioButton
        value={isPersonalReservation}
        onChange={setIsPersonalReservation}
      >
        <div className="flex flex-col gap-4">
          <RadioButtonItem label="El viaje es para mí" value={true} />
          <RadioButtonItem
            label="El viaje es para otra persona"
            value={false}
          />
        </div>
      </RadioButton>
      {actions(() => onSubmit(isPersonalReservation))}
    </div>
  );
};
