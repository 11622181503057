import { ClientType } from "app/client/main/models/ClientType";

interface ILoggedUser {
  client: {
    hasPriority: boolean;
    id: number;
    name: string;
    type: ClientType;
  };
  email: string;
  id: number;
  isDeleted: boolean;
  name: string;
  roles: string[];
}

export class LoggedUser {
  client: {
    hasPriority: boolean;
    id: number;
    name: string;
    type: ClientType;
  };
  email: string;
  id: number;
  isDeleted: boolean;
  name: string;
  roles: string[];

  constructor(args: ILoggedUser) {
    this.client = args.client;
    this.email = args.email;
    this.id = args.id;
    this.isDeleted = args.isDeleted;
    this.name = args.name;
    this.roles = args.roles;
  }

  get isClientBusinessUser() {
    return this.client.type === ClientType.BUSINESS;
  }
  get isClientPrivateUser() {
    return this.client.type === ClientType.PRIVATE;
  }
}
