import { useSuperQuery } from "@vadiun/react-hooks";
import { AuthorizedService } from "app/client/business/authorized/services/AuthorizedService";
import { httpClient } from "shared/services";
import {
  clientBusinessBuilder,
  ClientBusinessModel,
} from "../models/ClientBusiness";

const authorizedRepo = new AuthorizedService();
export class ClientBusinessService {
  keys = {
    byUserId: (userId: number) => ["clientBusiness", { userId }],
  };
  async getByUserId(id: number): Promise<ClientBusinessModel> {
    const res = await httpClient.get(`business-clients/${id}`);
    return clientBusinessBuilder.fromBackEnd(res);
  }

  getClientBusinessAuthorized(idClient: number) {
    return authorizedRepo.getAuthorizeds(idClient);
  }
}

const repo = new ClientBusinessService();

export const useBusinessClientByUserIdQuery = (
  id: number,
  options: { enabled: boolean }
) => useSuperQuery(repo.keys.byUserId(id), () => repo.getByUserId(id), options);

export const useBusinessClientRequirementsByUserIdQuery = (
  id: number,
  options: { enabled: boolean }
) =>
  useSuperQuery(
    repo.keys.byUserId(id),
    async () => {
      const client = await repo.getByUserId(id);
      return client.requirements;
    },
    options
  );
