import { createContext, useContext } from "react";

interface Props {
  value: any;
  onChange: (value: any) => void;
  children: React.ReactNode;
}

const RadioButtonContext = createContext(
  {} as { value: any; onChange: (valud: any) => void }
);

export const RadioButton = ({ value, onChange, children }: Props) => {
  return (
    <RadioButtonContext.Provider value={{ value, onChange }}>
      {children}
    </RadioButtonContext.Provider>
  );
};

interface RadioButtonItemProps {
  value: any;
  label: React.ReactNode;
}

export const RadioButtonItem = ({ value, label }: RadioButtonItemProps) => {
  const context = useContext(RadioButtonContext);
  const selected = context.value === value;

  return (
    <button
      className={`text-typo-600 flex items-center gap-4 rounded-lg border-2 bg-gray-100 py-4 px-8 text-sm ${
        selected ? "border-primary-400" : "border-gray-100"
      }`}
      onClick={() => context.onChange(value)}
    >
      <input
        type="radio"
        checked={selected}
        readOnly
        className="accent-primary-500"
      />
      {label}
    </button>
  );
};
