import React, { useEffect } from 'react';
import { EditUserForm } from './forms/edit/EditUserForm';
import { EditUserFormType } from './forms/edit/EditUserFormType';
import { useLocation, useNavigate } from 'react-router-dom';
import { Solicitante } from '../main/models/Solicitante';

interface Props {
  edit: (UserValues: EditUserFormType) => Promise<void>;
}
export function UserEdit({ edit }: Props) {
  const { state } = useLocation()
  const solicitante = state as Solicitante
  const navigate = useNavigate()

  useEffect(() => {
    !solicitante?.id && navigate('/main/users/list', { replace: true });
  }, [state])
  
  return (
    <EditUserForm onSubmit={edit} /> 
  )
}
