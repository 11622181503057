import { Fab } from "@mui/material"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from 'react-router-dom';

export const BackNavigation = ({ className } : { className?: string }) => {
  const navigate = useNavigate()
  return (
    <Fab
      size="small"
      style={{ background: 'white'}}
      className={`m-4 shadow-sm shadow-primary-10 ${className}`}
      onClick={() => navigate(-1)}
    >
      <FaArrowLeft />
    </Fab>
  )
}