import {
  ChangePasswordPage,
  EmailVerificationErrorPage,
  EmailVerificationSuccessPage,
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
} from "app/auth/pages";
import { AuthProvider, useAuthService } from "app/auth/services";
import { ClientType } from "app/client/main/models/ClientType";
import { MainPage } from "app/main/pages";
import ErrorPage from "app/main/pages/ErrorPage";
import { ReservationClientBusinessCreatePage } from "app/reservation/clientBusiness/pages/ReservationClientBusinessCreatePage";
import { ReservationClientBusinessEditPage } from "app/reservation/clientBusiness/pages/ReservationClientBusinessEditPage";
import { ReservationClientPrivateCreatePage } from "app/reservation/clientPrivate/pages/ReservationClientPrivateCreatePage";
import { ReservationEnViajePage } from "app/reservation/pages/ReservationEnViajePage";
import { ReservationFinalizadaPage } from "app/reservation/pages/ReservationFinalizadaPage";
import { ReservationListPage } from "app/reservation/pages/ReservationListPage";
import { ReservationPendientePage } from "app/reservation/pages/ReservationPendientePage";
import { PublicReservationPage } from "app/reservation/public/PublicReservationPage";
import { UsersNavigation } from "app/users/main/navigation";
import Landing from "layout/pages/Landing";
import { createHashRouter, Navigate, Outlet } from "react-router-dom";
import { CreateUserPage } from '../app/users/CreateUserPage';
import { UserListPage } from '../app/users/UsersListPage';
import { EditUserPage } from "app/users/EditUserPage";

function RequireAuth({ children }: { children: JSX.Element }) {
  const authService = useAuthService();

  if (!authService.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" replace />;
  }

  return children;
}

const CreateReservationNavigation = () => {
  const authSrv = useAuthService();

  return authSrv.loggedUser === undefined ? null : authSrv.loggedUser.client
      ?.type === ClientType.BUSINESS ? (
    <ReservationClientBusinessCreatePage />
  ) : (
    <ReservationClientPrivateCreatePage />
  );
};

export const router = createHashRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    ),
    children: [
      { element: <Navigate to={"/main"} replace />, index: true },
      {
        path: "public/reservations/:id",
        element: <PublicReservationPage />,
      },
      {
        path: "auth",
        children: [
          { path: "login", element: <LoginPage />, index: true },
          { path: "register", element: <RegisterPage /> },
          { path: "forgot-password", element: <ForgotPasswordPage /> },
          {
            path: "change-password/:token",
            element: <ChangePasswordPage />,
          },
          {
            path: "email-verification/successful",
            element: <EmailVerificationSuccessPage />,
          },
          {
            path: "email-verification/unsuccessful",
            element: <EmailVerificationErrorPage />,
          },
        ],
      },

      {
        path: "main",
        element: (
          <RequireAuth>
            <MainPage>
              <Outlet />
            </MainPage>
          </RequireAuth>
        ),

        children: [
          {
            path: `landing`,
            element: <Landing />,
            errorElement: <ErrorPage />,
          },
          {
            index: true,
            element: <Navigate to={"reservations"} />,
            errorElement: <ErrorPage />,
          },
          {
            path: `reservations`,
            errorElement: <ErrorPage />,
            children: [
              { index: true, element: <ReservationListPage /> },
              { path: "create", element: <CreateReservationNavigation /> },
              { path: "edit/:id", element: <ReservationClientBusinessEditPage /> },
              { path: "on-course/:id", element: <ReservationEnViajePage /> },
              { path: "pending/:id", element: <ReservationPendientePage /> },
              {
                path: "finished/:id",
                element: <ReservationFinalizadaPage />,
              },
            ],
          },
          {
            path: `users`,
            errorElement: <ErrorPage />,
            children: [
              { path: "list", element: <UserListPage /> },
              { path: "create", element: <CreateUserPage /> },
              { path: "edit", element: <EditUserPage /> }
            ],
          },
        ],
      },
    ],
  },
]);
