const staging = window.location.href.includes("portal.llt.kairoxlab.xyz");
const prod = window.location.href.includes("portal.lltraslados.com.ar");

const env = {
  backEnd: prod
    ? "https://back.lltraslados.com.ar/api/"
    : staging
    ? "https://api.llt.kairoxlab.xyz/api/"
    : "http://localhost:8000/api/",
};
export default env;
