import { Button } from "@vadiun/react-components";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { EditUserFormType } from "./EditUserFormType";
import { userFormValidation } from "./EditUserFormValidation";
import { useLocation } from "react-router-dom";

interface Props {
  onSubmit: (value: EditUserFormType) => Promise<void>;
  initialValues?: EditUserFormType;
}

export function EditUserForm({
  onSubmit,
}: Props) {
  const location = useLocation()

  return (
    <Formik<EditUserFormType>
      initialValues={location.state}
      validationSchema={userFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
