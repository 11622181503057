import { Button } from "@vadiun/react-components";
import { AuthLayout } from "app/auth/components";
import { useAuthService } from "app/auth/services";
import ForgotPasswordIlustration from "assets/forgot-password.svg";
import Logo from "assets/logo.png";
import Logo2 from "assets/logo2.png";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
interface FormSchema {
  email: string;
}

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("El email es inválido")
    .required("El email es requerido"),
});

export const ForgotPasswordPage = () => {
  const authSrv = useAuthService();
  const [successEmailEmition, setSuccessEmailEmition] = useState(false);

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await authSrv.forgotPassword(value.email);
    setSuccessEmailEmition(true);
    formikHelpers.resetForm();
  };

  return (
    <AuthLayout
      ilustration={ForgotPasswordIlustration}
      title="Olvidé mi contraseña"
      subtitle=""
      logo={Logo}
    >
      <div className="flex w-full max-w-xl flex-col items-center md:w-1/2 ">
        <img src={Logo2} className="mb-8 h-[100px] md:hidden" alt="logo" />
        <h1 className="my-4 text-center text-2xl font-bold">
          ¿Olvidó su contraseña?
        </h1>
        <div
          className={
            "w-full transition" + (successEmailEmition ? " animate-shrink" : "")
          }
        >
          <p className="font-bold text-gray-500">
            Ingrese su email y le enviaremos un correo con instrucciones para
            cambiar su contraseña
          </p>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={Schema}
            onSubmit={submit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  className="bg-white"
                  name="email"
                  component={TextField}
                />
                <div className="my-8 w-full">
                  <Button
                    className="w-full"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Enviar email
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div
          className={
            successEmailEmition
              ? "transition delay-300 duration-300 ease-in-out"
              : "h-0 scale-0 transform"
          }
        >
          <p className="mb-12 text-center">
            Hemos enviado un correo con instrucciones para cambiar su contraseña
          </p>
          <Link to="/auth/login">
            <Button className="w-full" variant="contained">
              Entendido
            </Button>
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};
