import { httpClient } from "shared/services";
import { User } from "app/auth/models/User";

export const useAuthRepository = () => {
  const login = (x: { email: string; password: string }) =>
    httpClient.post("login", x);

  const add = (user: User) => httpClient.post("users", user);

  const remove = (user: User) => httpClient.delete("users/" + user.id);

  const edit = (user: User) => httpClient.put("users/" + user.id, user);

  const getUser = (id: number) => httpClient.get(`application-users/${id}`);

  const forgotPassword = async (email: string) =>
    await httpClient.post("forgot-password", {
      email,
    });

  const changePassword = (x: {
    password: string;
    token: string;
    email: string;
  }) =>
    httpClient.post("reset-password", {
      ...x,
      password_confirmation: x.password,
    });

  return {
    login,
    add,
    remove,
    edit,
    forgotPassword,
    changePassword,
    getUser,
  };
};
