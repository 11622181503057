import { Link as RouterLink, useRouteError } from "react-router-dom";
// @mui
import { Box, Button, Container, Typography } from "@mui/material";
// components

// ----------------------------------------------------------------------

export default function ErrorPage() {
  let error = useRouteError();
  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Typography variant="h3" paragraph>
              Error
            </Typography>
          </div>

          <div>
            <Typography>Hubo un error, intente de nuevo mas tarde.</Typography>
          </div>

          <div>
            <Typography sx={{ color: "text.secondary" }}>
              {(error as any)?.message ?? "Unknown error"}
            </Typography>
          </div>

          <Button
            to="/"
            component={RouterLink}
            size="large"
            variant="contained"
          >
            Ir al inicio
          </Button>
        </Box>
      </Container>
    </>
  );
}
