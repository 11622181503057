import { useAuthService } from "app/auth/services";
import { useAuthorizedsByClientBusinessIdQuery } from "app/client/business/authorized/services/AuthorizedService";
import { ClientBusinessModel } from "app/client/business/main/models/ClientBusiness";
import { useBusinessClientByUserIdQuery } from "app/client/business/main/services/ClientBusinessService";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Query } from "react-query";
import { domicilioInitialValues } from "shared/components";
import {
  ReservationServiceTypeEnum,
  RESERVATION_SERVICE_TYPE,
} from "../../main/models/ReservationServiceType";
import { useCreateBusinessClientReservationMutation } from "../../main/services/reservationService";
import { ReservationClientBusinessCreateType } from "../../main/services/reservationService/reservationCreateType";

export type FormRequeriments = Omit<
  ReservationClientBusinessCreateType["requirements"],
  "applicant_name" | "applicant_email"
>;

export type Passanger = ReservationClientBusinessCreateType["passanger"];

export type ItineraryInfo = {
  itinerary: ReservationClientBusinessCreateType["itinerary"];
  observation: string;
  reservationDateTime: Moment;
};

export type ClientRequeriments = Query<ClientBusinessModel["requirements"]>;

export type ReservationCreateViewModelType = ReturnType<
  typeof useReservationClientBusinessCreate
>;

export type Authorizeds = Query<
  {
    id: number;
    name: string;
  }[]
>;

export type Resume = {
  passanger: Passanger | undefined;
  reservationDateTime: string;
  cashPaymnet: boolean;
  serviceType: string;
  requirements: FormRequeriments & { authorizedName: string | undefined };
  itinerary: ItineraryInfo["itinerary"];
};

export const useReservationClientBusinessCreate = () => {
  const authSrv = useAuthService();
  /**
   * Requerimientos de la empresa a la que el usuario pertenece.
   */

  const clientBusinessQuery = useBusinessClientByUserIdQuery(
    authSrv.loggedUser?.client.id!,
    { enabled: authSrv.loggedUser?.client.id !== undefined }
  );

  const clientBusinessRequerimentsQuery = {
    ...clientBusinessQuery,
    data: clientBusinessQuery.data?.requirements,
  };

  const authorizedsQuery = useAuthorizedsByClientBusinessIdQuery(
    clientBusinessQuery.data?.id!,
    { enabled: clientBusinessQuery.data?.id !== undefined }
  );

  const createReservationMutation =
    useCreateBusinessClientReservationMutation();

  /**
   * States
   */
  const [step, setStep] = useState<
    | "paymentType"
    | "isPersonal"
    | "passangerInfo"
    | "requirements"
    | "itinerary"
    | "serviceType"
    | "resume"
  >("paymentType");

  const [isPersonalReservation, setIsPersonalReservation] = useState(true);
  const [isCashPayment, setIsCashPayment] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState(
    ReservationServiceTypeEnum.EJECUTIVO
  );
  const [passanger, setPassanger] = useState<
    ReservationClientBusinessCreateType["passanger"]
  >({
    name: "",
    email: "",
    phone: "",
  });
  const [requeriments, setRequeriments] = useState<FormRequeriments>({
    costCenter: "",
    buyOrder: "",
    employeeNumber: "",
    platformApplication: "",
    sector: "",
    tripNumber: "",
    authorizedId: "",
    data1: "",
    data2: "",
    data3: "",
  });
  const [resume, setResume] = useState<Resume | undefined>(undefined);

  const [itineraryInfo, setItineraryInfo] = useState<ItineraryInfo>({
    reservationDateTime: moment(),
    itinerary: [{ address: domicilioInitialValues }],
    observation: "",
  });

  const onPaymentChange = (isCashPayment: boolean) => {
    setIsCashPayment(isCashPayment);
    setStep("isPersonal");
  };

  const onIsPersonalChange = (isPersonal: boolean) => {
    setIsPersonalReservation(isPersonal);
    if (isPersonal) {
      setPassanger({
        name: authSrv.loggedUser!.name,
        email: authSrv.loggedUser!.email,
        phone: passanger.phone,
      });
    }
    setStep("passangerInfo");
  };

  const onPassangerInfoChange = (passanger: Passanger) => {
    setPassanger(passanger);
    if (!isCashPayment) {
      setStep("requirements");
      return;
    }
    setStep("itinerary");
  };

  const onRequirementsChange = (requirements: FormRequeriments) => {
    setRequeriments(requirements);
    setStep("itinerary");
  };

  const onItineraryChange = (values: ItineraryInfo) => {
    setItineraryInfo(values);
    setStep("serviceType");
  };

  const onServiceTypeChange = (serviceType: ReservationServiceTypeEnum) => {
    setServiceType(serviceType);
    setResume({
      passanger: passanger,
      cashPaymnet: isCashPayment,
      serviceType,
      requirements: {
        ...requeriments,
        authorizedName: requeriments.authorizedId
          ? authorizedsQuery.data!.find(
              (x: any) => x.id === Number(requeriments.authorizedId!)
            )?.name
          : "",
      },
      itinerary: itineraryInfo!.itinerary,
      reservationDateTime: itineraryInfo!.reservationDateTime.format(
        "DD/MM/YYYY HH:mm [hs.]"
      ),
    });
    setStep("resume");
  };

  const reset = () => {
    setStep("paymentType");
    setIsPersonalReservation(true);
    setIsCashPayment(true);
    setServiceType(ReservationServiceTypeEnum.EJECUTIVO);
    setPassanger({
      name: "",
      email: "",
      phone: "",
    });
    setRequeriments({
      costCenter: "",
      buyOrder: "",
      employeeNumber: "",
      platformApplication: "",
      sector: "",
      tripNumber: "",
      authorizedId: "",
      data1: "",
      data2: "",
      data3: "",
    });
    setResume(undefined);
    setItineraryInfo({
      reservationDateTime: moment(),
      itinerary: [{ address: domicilioInitialValues }],
      observation: "",
    });
  };

  const onCreateReservation = async () => {
    await createReservationMutation.mutate({
      clientId: 1,
      itinerary: itineraryInfo!.itinerary,
      observation: itineraryInfo.observation,
      requirements: {
        ...requeriments,
        applicant_name: authSrv.loggedUser!.name,
        applicant_email: authSrv.loggedUser!.email,
      }!,
      reservationDateTime: itineraryInfo!.reservationDateTime,
      passanger: passanger!,
      serviceType,
      payWithCash: isCashPayment,
    });
    reset();
  };

  const onIsPersonalPreviousStep = () => setStep("paymentType");
  const onPassangerInfoPreviousStep = () => setStep("isPersonal");
  const onRequirementsPreviousStep = () => setStep("passangerInfo");

  const onItineraryPreviousStep = () => {
    if (!isCashPayment) {
      setStep("requirements");
      return;
    }
    setStep("passangerInfo");
  };
  const onServiceTypePreviousStep = () => {
    setStep("itinerary");
  };

  const onResumePreviousStep = () => setStep("serviceType");

  return {
    step,
    clientBusinessRequerimentsQuery,
    cost_centers: clientBusinessQuery.data?.business_client_cost_centers,
    authorizedsQuery,
    isCashPayment,
    isPersonalReservation,
    serviceType,
    serviceTypes: RESERVATION_SERVICE_TYPE,
    resume,
    createReservationMutation,
    itineraryInfo,
    passanger,
    requeriments,
    onServiceTypeChange,
    onPassangerInfoChange,
    onRequirementsChange,
    onItineraryChange,
    onIsPersonalChange,
    onCreateReservation,
    onPaymentChange,
    onIsPersonalPreviousStep,
    onPassangerInfoPreviousStep,
    onRequirementsPreviousStep,
    onItineraryPreviousStep,
    onServiceTypePreviousStep,
    onResumePreviousStep,
  };
};
