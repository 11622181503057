import { Button, Table, TableColumn } from "@vadiun/react-components";
import { Solicitante } from "../main/models/Solicitante";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface Props {
  users: Solicitante[];
}

export const UserListTable = (props: Props) => {
  const columns: TableColumn<Solicitante>[] = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Acciones',
      name: 'name',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link
              state={props.users[index]}
              to='/main/users/edit'
              className="text-primary-500"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>
          </div>   
      }
    }
  ];

  const data = Array.isArray(props.users) ? props.users : [];

  return (
    <div className="w-full">
      <Table title={"Solicitantes"} data={data} columns={columns} />
    </div>
  );
};
